.package-info-container {
  margin: 0.3rem;
  width: 100%;
  height: 80%; }

.package-info-title {
  background-color: #26547a;
  height: 38px;
  color: #ffffff;
  border: 1px solid #1e4260;
  font-weight: bold;
  text-align: center;
  padding-top: 5px;
  margin-bottom: 1em; }

.package-info-title-span {
  padding-left: 5px;
  line-height: 25px;
  font-size: 16px;
  font-weight: bold; }

.package-info-content {
  width: 100%;
  margin: 2px; }

.button-container-div {
  width: 100%;
  padding: 5px; }

.button-content-left {
  width: 50%;
  display: inline-block;
  text-align: right; }

.border-danger-upload {
  border: 1px solid #dc3545; }

.select-style {
  margin-bottom: 0rem !important; }

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: block;
  cursor: pointer; }

.upload-btn {
  height: 38px;
  font-size: 14px;
  border-radius: 4px;
  color: #e8eef3;
  background-color: #6c757d;
  transition: background-color .3s, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.05);
  font-weight: bold;
  position: relative;
  text-align: center;
  white-space: nowrap;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  box-sizing: border-box;
  display: block;
  width: 100%; }

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer; }

#loginBtn {
  margin: 1rem 0; }
