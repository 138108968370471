.dropdown-item.active, .dropdown-item:active, .dropdown-item:hover {
  background-color: #3c8dbc;
  color: white; }

.autocompletecss .item-highlighted {
  color: white;
  background-color: #4095bf;
  border-radius: 15px; }

.config_settings h5 {
  background: #ececec; }

.subnav .nav a:hover, .subnav .nav .active {
  background: #3c8dbc;
  color: #fff;
  border: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14); }

.admin-tbody > .row:hover {
  background: #17a2b80d; }

.widget-header {
  border-left: 5px solid #2cc4cc;
  padding-left: 10px; }

.tab-selected {
  background: #17a2b8 !important; }

.tab-selected > a {
  color: white !important; }

.tab-selected-live {
  border-bottom: 2px solid #17a2b8 !important; }

.subnav.machine-info-bar > .machine-info-tabs > .activeTab > a {
  background: #3c8dbc !important;
  color: white !important;
  border: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14); }

.page-item.active > .page-link {
  background-color: #3c8dbc; }
