.backdrop {
  width: 100%;
  height: 100%;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5); }

.progressBarContainer {
  width: 25%;
  height: 25%;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 4px;
  color: white; }

.track {
  width: 100%;
  height: 30px;
  background-color: #222;
  border-radius: 10px;
  box-shadow: inset 0 0 5px #000; }

.thumb {
  width: 50%;
  height: 100%;
  border-radius: 8px;
  background-color: #06f792;
  font-weight: bold;
  color: white;
  -webkit-transition: width .3s ease;
  -o-transition: width .3s ease;
  transition: width .3s ease; }

.progress-bar-striped_custom {
  /*  font : normal normal normal 16px/1 FontAwesome;*/
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
  float: left;
  width: 100%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 3s ease;
  -moz-transition: width 3s ease;
  -o-transition: width 3s ease;
  transition: width 3s ease;
  animation: progress-bar-stripes 2s linear infinite;
  background-color: #26547a;
  border-radius: 8px;
  font-weight: bold; }

@keyframes progress-bar-stripes {
  0% {
    background-position: 40px 0; }
  100% {
    background-position: 0 0; } }
