.subnav-menu > .nav {
  margin-top: 0 !important;
  padding-top: calc(6rem + 16px + 1rem); }

.navbar {
  height: auto; }

.boschStrip {
  border-top: 10px solid transparent; }

.navbar .navbar-nav:not(.action-items) > li:hover i, .navbar .navbar-nav:not(.action-items) > li:hover span {
  color: #fff !important; }

.navbar .navbar-nav:not(.action-items) > li span {
  font-size: 0.8rem; }

.navbar .navbar-nav .show > .nav-link, .navbar .navbar-nav .active > .nav-link, .navbar .navbar-nav .nav-link.show, .navbar .navbar-nav .nav-link.active {
  color: #fff;
  background-color: #35a6c9a8;
  border-radius: 4px;
  padding: 0.75rem 0.45rem; }

.treeview-menu > li:hover {
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2; }

/*................Navbar Search Compoenent......................*/
.navbar-search input {
  border: none;
  background: inherit;
  border-bottom: 1px solid #ccc;
  border-radius: 0px; }

.navbar-search button.btn-search, .navbar-search button.btn-search {
  border: none;
  bottom: 2px;
  background: inherit; }

.navbar-search > button.btn-search > i.material-icons {
  font-size: 25px; }

/*......................................*/
