@charset "UTF-8";
/* #generationSelect_Label{color: red; font-size: 11px;}
#packagename_Label{} */
#listDropdown {
  background: white; }

#role_name {
  display: block; }

.userManagementError {
  color: red;
  font-size: 11px; }

.userManagement {
  margin-top: 7.5rem; }

.userManagement .modal-content {
  max-height: 75%; }

.app-content {
  width: 100%;
  height: 100%; }

.userManagement .container-fluid {
  padding-left: 15px !important; }

.userManagement .admin-table {
  padding-left: 2px !important;
  padding-top: 6px !important; }

a {
  color: inherit; }

a:hover {
  color: unset;
  text-decoration: unset; }

a:not([href]):not([tabindex]) {
  color: unset;
  text-decoration: unset; }

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: unset;
  text-decoration: unset; }

a:not([href]):not([tabindex]):focus {
  outline: unset; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 300;
  line-height: 1.1;
  color: inherit; }

@media (min-width: 1200px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1400px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333333%; }
  .offset-xl-2 {
    margin-left: 16.666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333333%; }
  .offset-xl-5 {
    margin-left: 41.666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333333%; }
  .offset-xl-8 {
    margin-left: 66.666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333333%; }
  .offset-xl-11 {
    margin-left: 91.666667%; } }

.valid-feedback {
  color: #6cc788; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #6cc788; }

.was-validated .form-check-input:valid + .form-check-label, .form-check-input.is-valid + .form-check-label {
  color: #6cc788; }

.was-validated .custom-control-input:valid ~ .custom-control-description, .custom-control-input.is-valid ~ .custom-control-description {
  color: #6cc788; }

.was-validated .custom-file-input:valid ~ .custom-file-control, .custom-file-input.is-valid ~ .custom-file-control {
  border-color: #6cc788; }

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem; }

.input-group-prepend {
  margin-right: -2px; }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none;
  outline: 0;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  -webkit-transition: background-color .3s , border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transition: background-color .3s, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out; }

.btn:focus, .btn.focus {
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12); }

.btn-success {
  background-color: #6cc788;
  border-color: #6cc788; }

.btn-success.disabled, .btn-success:disabled {
  background-color: #6cc788;
  border-color: #6cc788; }

.btn-warning {
  color: #111;
  background-color: #fcc100;
  border-color: #fcc100; }

.btn-warning.disabled, .btn-warning:disabled {
  background-color: #fcc100;
  border-color: #fcc100; }

.btn-outline-success {
  color: #6cc788;
  border-color: #6cc788; }

.btn-outline-success:hover {
  background-color: #6cc788;
  border-color: #6cc788; }

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #6cc788; }

.btn-outline-success:not([disabled]):not(.disabled):active, .btn-outline-success:not([disabled]):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  background-color: #6cc788;
  border-color: #6cc788; }

.btn-outline-warning {
  color: #fcc100;
  border-color: #fcc100; }

.btn-outline-warning:hover {
  background-color: #fcc100;
  border-color: #fcc100; }

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #fcc100; }

.btn-outline-warning:not([disabled]):not(.disabled):active, .btn-outline-warning:not([disabled]):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  background-color: #fcc100;
  border-color: #fcc100; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  opacity: 0;
  display: block;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  pointer-events: none;
  -webkit-transition: all 250ms cubic-bezier(0.24, 0.22, 0.015, 1.56);
  transition: all 250ms cubic-bezier(0.24, 0.22, 0.015, 1.56); }

.dropdown-menu.d-block {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transform-origin: top right;
  -moz-transform-origin: top right;
  -ms-transform-origin: top right;
  transform-origin: top right; }

.card {
  box-shadow: 0 1px 15px 1px rgba(81, 77, 92, 0.08); }

.badge-success {
  background-color: #6cc788; }

.badge-warning {
  background-color: #fcc100; }

.tooltip {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Poppins, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.popover {
  font-family: "Poppins",-apple-system, BlinkMacSystemFont, "Segoe UI", Poppins, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.bg-success {
  background-color: #6cc788 !important; }

.bg-danger {
  background-color: #dc3545 !important;
  color: #fff !important; }

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #bd2130 !important;
  color: #fff !important; }

.bg-light {
  background-color: #f8f9fa !important;
  color: #000 !important; }

a.bg-light:focus, a.bg-light:hover {
  background-color: #dae0e5 !important;
  color: #000 !important; }

.bg-dark {
  background-color: #343a40 !important;
  color: #fff !important; }

a.bg-dark:focus, a.bg-dark:hover {
  background-color: #1d2124 !important;
  color: #fff !important; }

.bg-white {
  background-color: #fff !important;
  color: #000 !important; }

.border-success {
  border-color: #6cc788 !important; }

.border-warning {
  border-color: #fcc100 !important; }

@media (min-width: 1200px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1400px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

.text-success {
  color: #6cc788 !important; }

a.text-info:focus, a.text-info:hover {
  color: unset !important; }

.text-warning {
  color: #fcc100 !important; }

.text-muted {
  color: #dadddf !important; }

.text-inActive {
  color: #adb3af !important;
  cursor: auto !important; }

.text-active {
  cursor: pointer !important; }

.text-secondary-custom {
  color: #3e444a !important; }

/****************/
html, body, #app {
  height: 100%;
  padding: 0;
  margin: 0; }

.animList-appear {
  opacity: 0.01; }

.react-tabs__tab-panel--selected {
  height: 100%; }

.animList-appear.animList-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in; }

input[type=checkbox] {
  color: #ccc; }

input[type=checkbox]:checked {
  color: #f00; }

select.loadinggif {
  background-image: url(inputloader.gif);
  background-size: 25px 25px;
  background-position: right center;
  background-repeat: no-repeat;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  cursor: progress; }

.loadinggif .Select-arrow-zone {
  background-image: url(inputloader.gif);
  background-size: 25px 25px;
  background-position: right center;
  background-repeat: no-repeat;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  cursor: progress; }

.loadinggif .Select-arrow {
  display: none; }

body {
  background-color: #f1f1f1;
  font-size: 0.9rem;
  color: #343a40 !important; }

html, body, h1, h2, h3, h4, h5 {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Poppins, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.bs-display-middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%); }

.bs-display-bottommiddle {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%); }

.bg-darkgray {
  background-color: #353535; }

.bg-theme-primary {
  color: #fff !important;
  background-color: #283e4a !important; }

.bg-theme-lite {
  color: #000 !important;
  background-color: #fff !important; }

.bg-theme-grey {
  background-color: #ddd !important; }

.bg-theme-danger {
  background-color: #dc3545 !important; }

.itrams_theme .navbar-dark {
  background-color: #283e4a !important;
  color: #fff !important; }

.bs-tiny {
  font-size: 0.7rem !important; }

.bs-small {
  font-size: 0.8rem !important; }

.bs-medium {
  font-size: 0.9rem !important; }

.bs-large {
  font-size: 1rem !important; }

.btn, button {
  cursor: pointer;
  position: relative;
  overflow: hidden; }

button:after, .btn:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%; }

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1; }
  20% {
    transform: scale(25, 25);
    opacity: 1; }
  100% {
    opacity: 0;
    transform: scale(40, 40); } }

button:focus:not(:active)::after, .btn:focus:not(:active)::after {
  animation: ripple 1s ease-out; }

.btn-group .btn {
  min-width: 75px;
  border-radius: .25rem !important;
  margin-right: 5px; }

.btn:active {
  top: 2px; }

.btn:before {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0; }

.h-95 {
  height: 95%; }

.btn-success {
  background-color: #00a65a !important; }

/* .row.bs-padding > .col,.row.bs-padding > .col-sm-12,.row.bs-padding > .col-sm-3,.row.bs-padding > .col-sm-9{
  padding: 2px;
} */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/*..............Main Loader...............*/
.init-loader {
  position: absolute;
  top: 50%;
  left: 48%; }

.line {
  animation: expand 1s ease-in-out infinite;
  border-radius: 10px;
  display: inline-block;
  transform-origin: center center;
  margin: 0 3px;
  width: 1px;
  height: 25px; }

.line:nth-child(1) {
  background: #27ae60; }

.line:nth-child(2) {
  animation-delay: 180ms;
  background: #f1c40f; }

.line:nth-child(3) {
  animation-delay: 360ms;
  background: #e67e22; }

.line:nth-child(4) {
  animation-delay: 540ms;
  background: #2980b9; }

@keyframes expand {
  0% {
    transform: scale(1); }
  25% {
    transform: scale(2); } }

/*.....................................*/
.__react_component_tooltip {
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  text-transform: none;
  z-index: 1200 !important; }

#service .form-control:disabled, .form-control[readonly] {
  background-color: #ffffff;
  opacity: 1; }

.statusTabSelected {
  color: #ffffff !important;
  background-color: #17a2b8; }

.form-group select, .form-row select, .view-softwaremgmt-report select {
  margin-bottom: 1.5rem;
  height: 2rem !important;
  font: 300 13px/1.55 "Open Sans",sans-serif; }

.form-select select, .input_group_div select {
  margin-bottom: 0 !important; }

.dropdown-menu {
  top: 87%; }

.form-group {
  margin-bottom: 0px; }

.no-scrollX {
  overflow-x: hidden; }

.no-scroll {
  overflow: hidden; }

.bs-center {
  height: 4rem;
  flex-shrink: 1 !important;
  display: flex;
  align-items: center;
  justify-content: center; }

.btn-xs {
  padding: 0.4rem;
  display: inline-block;
  margin-right: 0.2rem;
  width: 2rem;
  height: 2rem; }

.btn-xs-details {
  color: #868e96;
  border-color: #e9ecef; }

.btn-xs-details:hover {
  background: #17a2b8;
  color: white; }

.btn-xs i {
  font-size: 19px; }

.btn-xs-danger {
  color: #dc3545;
  border-color: #dc3545; }

.btn-xs-danger:hover {
  background: #dc3545;
  color: white; }

.btn-xs-success {
  color: #6cc788;
  border-color: #6cc788; }

.btn-xs-success:hover {
  background: #6cc788;
  color: white; }

.btn-xs-default {
  color: #343a40;
  border-color: #343a40; }

.btn-xs-default:hover {
  background: #343a40;
  color: white; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  padding-right: 1px;
  padding-left: 1px; }

.col-dm-8, .col-dm-4 {
  padding-right: 0px;
  padding-left: 0px; }

.form-control {
  line-height: 1.7;
  padding: .15rem .75rem; }

.reportTable .admin-table > .admin-tbody > tr > td, .reportTable .admin-table > .admin-theader > tr > th {
  text-align: center;
  vertical-align: middle !important;
  line-height: 15px !important;
  padding-left: 1rem;
  padding-right: 1rem;
  border-top: unset; }

.form-row label {
  margin-bottom: .1rem;
  font-weight: 600;
  -webkit-text-transform: uppercase;
  text-transform: uppercase; }

.form-group label {
  margin-bottom: .1rem;
  font-weight: 600;
  -webkit-text-transform: uppercase;
  text-transform: uppercase; }

.control-label {
  margin-bottom: .1rem;
  font-weight: 600;
  -webkit-text-transform: uppercase;
  text-transform: uppercase; }

h1, .h1 {
  font-size: 2.2rem; }

h2, .h2 {
  font-size: 1.9rem; }

h3, .h3 {
  font-size: 1.45rem; }

h4, .h4 {
  font-size: 1.2rem; }

h5, .h5 {
  font-size: 1.10rem; }

h6, .h6 {
  font-size: 0.85rem; }

.boschStrip {
  border-top: 5px solid transparent;
  border-image: url(boschstrip.png) 30 stretch; }

.widget {
  background-color: #fff !important;
  color: #343a40 !important;
  border: none !important; }

.widget-options {
  position: absolute;
  right: 16px;
  top: 14px;
  z-index: 5; }

.bar {
  padding-left: 0;
  padding-right: 0;
  border-radius: 3px;
  margin-top: 0;
  margin-bottom: 1rem; }

.bar-item {
  display: block;
  position: relative;
  padding: 12px 0px; }

.vCard .bar-body span {
  word-wrap: break-word;
  display: block; }

.no-padding .bar-item {
  padding-left: 0;
  padding-right: 0; }

.bar-left {
  float: left; }

.bar-left + .bar-body {
  margin-left: 100px;
  padding-left: 0.5rem; }

.react-grid-layout {
  position: absolute !important;
  height: 100% !important;
  width: 100% !important; }

.react-grid-item {
  z-index: 5; }

.nav-menu-list .nav-link {
  width: 4.5rem !important;
  padding: 3px !important;
  height: 50px; }

.nav-text {
  text-align: center;
  display: block;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 500; }

.nav-text span {
  display: block; }

/*..........................................*/
/*......................Landing Dash Overview............*/
.dash-container {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  justify-content: space-around; }

.flex-1 {
  flex: 1; }

/*...............................................*/
/*.................Machine Search Navbar........*/
.autocompletecss {
  padding: 0.5rem 1rem; }

.autocompletecss > div > div {
  box-shadow: unset !important;
  background: unset !important; }

.autocompletecss .menu {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  background: white;
  border: 1px solid #cccccc; }

.autocompletecss .item {
  padding: 8px 10px;
  cursor: default;
  color: #495057;
  background: #ffffff;
  border-bottom: 1px solid #f2f2f2; }

.autocompletecss .item-header {
  background-color: #eeeeee;
  color: #454545;
  font-weight: bold; }

.autocompletecss input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.7;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }

#mapViewModal > div > div > h5 {
  margin-left: 2%; }

#mapViewModal hr {
  margin-bottom: 0; }

/*................................*/
/*..............view-device-log.........*/
.view-device-log .autocompletecss > div {
  width: 100%; }

.view-device-log .autocompletecss {
  padding-top: 0.25rem !important; }

.view-device-log label {
  margin-bottom: .1rem;
  font-weight: 500;
  -webkit-text-transform: uppercase;
  text-transform: uppercase; }

.view-device-log input {
  height: 2rem; }

/*.......................................*/
/*....................................*/
/*..................Config Settings................*/
.arrowSymbol {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg); }

.config_settings h5 {
  height: 40px;
  align-items: center; }

.arrowSymbol.rotate-arrow {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg); }

.dlr-item.showDealerData .arrowSymbol {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg); }

.dlr-item.showDealerData .dlr-item-body {
  max-height: 200px; }

.dlr-item-body {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-out; }

/*...................................*/
/*.............subnav...................*/
.subnav-menu {
  width: 2.5rem !important;
  height: 100% !important;
  top: 0px !important; }

.subnav-menu > .nav > li > a {
  width: 100% !important;
  position: relative;
  padding: 0.4rem !important;
  display: flex !important;
  align-items: center;
  justify-content: center; }

.subnav-menu.sub-nav-xs > .nav > li > a {
  padding: 0.25rem !important; }

.sub-nav-less > .nav > li {
  padding: 0; }

.sub-nav-more > .nav > li {
  padding: 5px 0; }

.subnav-menu .nav a:hover .title {
  pointer-events: auto;
  opacity: 1;
  transform: scale(1); }

.subnav-menu > .nav > li > a > i {
  position: relative;
  z-index: 2; }

.subnav-menu > .nav > li {
  flex: 1;
  min-height: 25px;
  width: 100%; }

.subnav-menu > .nav > li > a > span.title {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 110%;
  height: 100%;
  padding-left: 15px;
  padding-right: .75em;
  pointer-events: none;
  transform: scale(0.8);
  z-index: 1;
  opacity: 0;
  background-color: inherit;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  color: inherit;
  line-height: 40px;
  /* -webkit-transform: translate(-100%,0);
    -ms-transform: translate(-100%,0);
     transform: translate(-100%,0); */
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1); }

.subnav {
  color: #000;
  background-color: white;
  position: fixed;
  left: 0;
  z-index: 6;
  -webkit-transition: left .5s ease;
  transition: left .5s ease;
  background-clip: padding-box;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14); }

.subnav-menu > .nav {
  flex-direction: column;
  flex-grow: 1;
  margin-top: 75px; }

@media only screen and (max-height: 700px) {
  .subnav-menu.sub-nav-xs > .nav > li > a {
    padding: 0.1rem !important; } }

/*................operations-page..........*/
.operations-page {
  padding-left: 2.6rem; }

.sidenav-layout .operations-page {
  padding-left: 0; }

.operations-page > .subnav-menu > .nav > li, .trips-page > .subnav-menu > .nav > li {
  flex: 0;
  flex-grow: 0; }

.operations-page div.autocompletecss {
  width: 100% !important; }

.operations-page div.autocompletecss > div:first-child {
  width: 100% !important; }

.operations-page .trackingCard {
  background-color: #283e4a;
  background-color: #2e3e4ee6; }

.trips-page .autocompletecss > div {
  width: 100%; }

.trips-page .autocompletecss {
  padding: 0.5rem 0rem; }

/*................................*/
/*.............Themer Page.............*/
.themer-page .locations-scroll span.btn {
  color: tomato;
  opacity: 0; }

.themer-page .locations-scroll li.paint span.btn {
  opacity: 1; }

/*...................................*/
/*.......................................*/
/*..............Checkbox ...................*/
.check-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 0.65rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* Hide the browser's default checkbox */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer; }

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #eee; }

/* On mouse-over, add a grey background color */
.check-container:hover input ~ .checkmark {
  background-color: #03a76a;
  border-radius: 0.2rem; }

/* When the checkbox is checked, add a blue background */
.check-container input:checked ~ .checkmark {
  background-color: #2196F3;
  border-radius: 0.2rem; }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
  display: block; }

/* Style the checkmark/indicator */
.check-container .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

/*........................................*/
.filterNav {
  position: absolute;
  left: 100%;
  top: 0px;
  height: 100%;
  border-right-width: 0;
  width: 40px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25); }

.filterNav li {
  flex: 1; }

.filter-btn {
  flex: 2;
  cursor: pointer;
  background-color: #fff;
  color: #00bc66; }

.sidebar.padding {
  padding-left: 10px; }

.sidebar {
  height: 100%;
  width: 22%;
  background-color: #fff;
  position: fixed;
  z-index: 1020;
  background-clip: padding-box; }

.sidebar.sidebarReports {
  top: 4.74rem;
  left: 0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14);
  transition: left 0.3s; }

.sidebar.sidebarFleet {
  top: 4.74rem;
  left: -22%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14);
  transition: left 0.3s; }

.sidebar.sidebarFleet.inactive {
  left: 0%; }

.filter-side-bar {
  width: 22%;
  background-color: #fff;
  position: fixed;
  z-index: 1020;
  background-clip: padding-box;
  top: 8.5rem;
  /*   box-shadow: 0 1px 2px 0 rgba(0,0,0,.14); */
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  right: -22%;
  height: auto !important;
  -webkit-transition: right 0.5s ease;
  transition: right 0.5s ease; }

.shadow {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.03), 0 1px 0 rgba(0, 0, 0, 0.03); }

.filter-side-bar > a.filter-btn {
  position: absolute;
  right: 100%;
  top: 0px;
  padding: 7px 10px;
  z-index: 1045;
  border-right-width: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #353535;
  color: #fff;
  -webkit-box-shadow: 5px 5px 12px -5px black;
  -moz-box-shadow: 5px 5px 12px -5px black;
  box-shadow: -5px 5px 12px -5px black; }

.sidebar.sidebarReports.inactive {
  left: -22%; }

.sidebar.inactive .filter-arrow {
  transform: rotate(180deg); }

.sidebarright {
  height: 70vh;
  width: 332px;
  top: 173px;
  background-color: #fff;
  position: fixed;
  z-index: 1050;
  background-clip: padding-box;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14);
  right: 0; }

.filter-side-bar.active {
  right: 0; }

.headerConfigFilter {
  top: 16% !important; }

.sidebarright.inactive {
  display: none; }

.shift-view {
  margin-left: 25% !important; }

.reportTable.shift-view {
  margin-left: 22% !important; }

#reportTab .form-group {
  margin-bottom: 0px !important; }

@media only screen and (max-width: 1200px) {
  .sidebarright {
    width: 225px !important; }
  .sidebar {
    width: 30%; }
  .sidebar.sidebarFleet {
    left: -30%; }
  .sidebar.sidebarReports.inactive {
    left: -30%; }
  .shift-view {
    margin-left: 33% !important; }
  .reportTable.shift-view {
    margin-left: 30% !important; } }

.sw-btn {
  /*padding: 10px 15px;*/
  z-index: 1045; }

.sidebar-add-menu {
  background: white;
  top: 0;
  width: 100%;
  position: absolute; }

.input_group_div {
  padding-left: 20px;
  padding-right: 20px;
  background-color: #f2f2f2 !important;
  height: 4.8rem;
  margin-bottom: 10px;
  display: flex;
  align-items: center; }

#reportTab .input_group_div {
  background-color: unset !important;
  height: unset;
  margin-bottom: 10px;
  display: flex;
  align-items: center; }

#reportTab .Select {
  padding-left: 20px;
  padding-right: 20px; }

#reportTab .Select {
  padding-left: 20px;
  padding-right: 20px; }

#reportTab {
  max-height: 87vh;
  overflow-y: auto; }

.input_group_div.select-div-group {
  display: block !important;
  padding-top: 30px !important; }

.sidebar-add-menu .form-select {
  padding-left: 20px;
  padding-right: 20px;
  background-color: #f8f9fa !important;
  height: 100px;
  margin-bottom: 10px;
  display: flex;
  align-items: center; }

/*..................................*/
.widget .widget-options {
  cursor: pointer; }

.table-header {
  padding: 1.0rem 2.25rem;
  margin-bottom: 0;
  height: 4rem;
  flex-shrink: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.btn-round {
  width: 44px;
  height: 44px;
  line-height: 48px;
  border-radius: 500px;
  padding: 5px;
  display: inline-block;
  text-align: center;
  color: inherit;
  cursor: pointer; }

.btn-square-add {
  width: 35px;
  height: 35px;
  line-height: 40px;
  border-radius: 0.25rem;
  padding: 5px;
  display: inline-block;
  text-align: center;
  color: inherit;
  cursor: pointer;
  border: none !important; }

.btn.disabled, .btn:disabled {
  pointer-events: none; }

.btn-square {
  width: 40px;
  height: 30px;
  line-height: 20px;
  display: inline-flex;
  text-align: center;
  color: inherit;
  align-items: center;
  justify-content: center; }

/*..........Management Table.............*/
.admin-theader {
  height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #464a4e;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #deecf6;
  font-weight: bold; }

.admin-theader > .row > div > span {
  font-size: 0.75rem;
  cursor: pointer; }

.admin-theader > .row > div.sort_desc::after {
  font-family: "Material Icons";
  content: "\E313";
  padding-left: 1px;
  opacity: 0.5;
  color: #f8f9fa;
  background: #464a4e;
  border-radius: 0.25rem;
  padding-right: 1px;
  margin-left: 5px;
  height: 1.15rem;
  margin-top: 1px; }

.admin-theader > .row > div {
  display: inline-flex;
  justify-content: center; }

.admin-theader > .row > div.sort_asc::after {
  font-family: "Material Icons";
  content: "\E316";
  padding-left: 1px;
  opacity: 0.5;
  color: #f8f9fa;
  background: #464a4e;
  border-radius: 0.25rem;
  padding-right: 1px;
  margin-left: 5px;
  height: 1.15rem;
  margin-top: 1px; }

.admin-theader > .row {
  justify-content: space-evenly;
  align-items: center;
  height: 3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: #e6e9ed !important;
  color: #000 !important; }

.admin-theader > .row > div > span {
  font-size: 0.8rem !important; }

.admin-list-item > .col > span {
  font-size: 0.8rem !important; }

.admin-tbody > .row {
  justify-content: space-evenly;
  align-items: center; }

.admin-list-item {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 3.0rem;
  background-color: inherit; }

.admin-list-item.disabled .col span, .admin-list-item.disabled ~ .row .col span {
  color: lightgray; }

.fadeInAnim {
  /* make things invisible upon start */
  -webkit-animation: fadeIn ease-in 1;
  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;
  -webkit-animation-fill-mode: forwards;
  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards; }

.fast-anim {
  -webkit-animation-duration: 300ms;
  -moz-animation-duration: 300ms;
  animation-duration: 300ms; }

.slow-anim {
  -webkit-animation-duration: 500ms;
  -moz-animation-duration: 500ms;
  animation-duration: 500ms; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.admin-page .admin-list-item > div, .admin-list-item > div:not(:last-child) span, .reportTable .admin-list-item > div {
  width: 100px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: inline-block; }

/* for tabledata even space */
.admin-tbody > .innerRowTable > .row {
  justify-content: space-evenly; }

.row-custm {
  height: 3.5rem !important;
  border-bottom: 1px solid #e6e9ed !important;
  background: #fff !important;
  box-shadow: 0 1px 15px 1px rgba(81, 77, 92, 0.08) !important; }

.admin-page .admin-list-item > div:not(:last-child) span, .reportTable .admin-list-item > div:not(:last-child) span {
  display: block; }

.admin-table {
  flex-shrink: 2;
  height: calc(100% - 8rem); }

.admin-page > .card.widget > .h-100.no-scroll {
  display: flex;
  flex-direction: column; }

.admin-page-layout {
  padding-left: 2.6rem; }

.admin-table > .admin-tbody {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 3rem);
  background: #fafafa; }

.admin-list-item > .col > span {
  word-wrap: break-word; }

.admin-list-item:nth-of-type(even), .innerRowTable:nth-of-type(even), .reportTable .admin-table > .admin-tbody > tr:nth-of-type(even), .bar-item.alert-tab:nth-of-type(odd) {
  background: #ffffff; }

.admin-list-item:nth-of-type(odd).innerRowTable:nth-of-type(odd), .reportTable .admin-table > .admin-tbody > tr:nth-of-type(odd), .bar-item.alert-tab:nth-of-type(even) {
  background: #fafafa; }

.innerRowTable > .admin-list-item:nth-of-type(odd) {
  background: unset; }

/*..............................*/
.bosch-color1 {
  color: #8cc759 !important;
  font-size: 35px !important; }

.bosch-color2 {
  color: #8c6daf !important;
  font-size: 35px !important; }

.bosch-color3 {
  color: #ef5d74 !important;
  font-size: 35px !important; }

.bosch-color4 {
  color: #f9a74b !important;
  font-size: 35px !important; }

.bosch-color5 {
  color: #60beeb !important;
  font-size: 35px !important; }

.loading {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  height: 240px;
  font-size: 0; }

.loading ul {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.loading li {
  height: 0;
  display: inline-block;
  width: 0;
  margin: 0;
  height: 10px;
  width: 10px;
  border: 3px solid #2b8ccd;
  border-radius: 100%;
  transform: transformZ(0);
  animation: LOADING 2s infinite; }

.loading li:nth-child(1n) {
  left: -20px;
  animation-delay: 0s; }

.loading li:nth-child(2n) {
  left: 0;
  animation-delay: 0.2s; }

.loading li:nth-child(3n) {
  left: 20px;
  animation-delay: 0.4s; }

@keyframes LOADING {
  0% {
    transform: scale(0.5);
    background: #2b8ccd; }
  50% {
    transform: scale(1);
    background: white; }
  100% {
    transform: scale(0.5);
    background: #2b8ccd; } }

.cs-loader {
  position: absolute;
  z-index: 1032;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #00000099; }

.cs-loader-inner {
  transform: translate(-50%, -50%);
  top: 50%;
  position: absolute;
  min-height: 209px;
  height: auto;
  width: 22%;
  left: 50%;
  align-items: center;
  border-radius: 4px;
  color: #ffffff;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #283e4a;
  background-color: #283e4af2; }

.border_failed {
  border: 3px solid tomato !important; }

.border_success {
  border: 3px solid #03a76a !important; }

.login-loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite; }

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/*......tracking loader........*/
#tracking-loader > .loader {
  width: 100%;
  background: #0000006b;
  z-index: 50; }

#tracking-loader > .loader > .login-loader {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 30%;
  left: 45%; }

/*.......................*/
/*............test loader...............*/
svg .loader__rotation, svg .loader__path {
  stroke-linecap: square;
  stroke-width: 3; }

.loader__path, .loader__error {
  opacity: 0;
  stroke: #3498db;
  transition: opacity 0.5s, stroke-dashoffset 0.5s; }

.loader__error {
  stroke: red; }

.loader__background {
  opacity: 0;
  /*fill: #1ecd97;*/
  stroke: #3498db;
  fill: none;
  transition: opacity 0.1s, stroke-dashoffset 0.5s; }

.loader__rotation {
  /*   stroke: #ff0000; */
  stroke: #3498db;
  fill: none;
  stroke-dashoffset: 0;
  stroke-dasharray: 1,200;
  transform-origin: center center;
  transition-delay: 1s;
  transition: opacity 0.1s, stroke-dasharray 0.5s, transform 0.5s;
  animation: dash 1.5s ease-in-out infinite; }

@keyframes rotate {
  100% {
    transform: rotate(360deg); } }

@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124px; } }

.loader.done .loader__path,
.loader.done .loader__background {
  opacity: 1;
  stroke-dashoffset: 0; }

.loader.done .loader__rotation, .loader.failed .loader__rotation {
  opacity: 0;
  /*   stroke-dasharray: 200,0;
  animation: none; */ }

.loader.failed .loader__error,
.loader.failed .loader__background, .loader.failed .loader__error {
  opacity: 1;
  stroke-dashoffset: 0; }

.loader.failed .loader__background {
  stroke: red; }

/* css for the demo */
div.loader {
  position: relative;
  /*margin: 0px auto;*/
  width: 60px; }

div.loader:before {
  content: '';
  display: block;
  padding-top: 100%; }

svg {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; }

/*............................*/
.pointer {
  cursor: pointer !important; }

.replay-route-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 50vh; }

.locations-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
  min-height: 330px; }

.assign-scroll {
  max-height: 500px;
  min-height: 230px; }

.bs-widget-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  min-height: 0; }

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5; }

::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }

::-webkit-scrollbar-thumb {
  background-color: #0ae;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(0.5, rgba(255, 255, 255, 0.2)), color-stop(0.5, transparent), to(transparent)); }

.cover {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%; }

.on {
  border-top: 3px solid #6cc788 !important;
  border-radius: 10px; }

.off {
  border-top: 3px solid #dc3545 !important;
  border-radius: 10px; }

.idle {
  border-top: 3px solid #fcc100 !important;
  border-radius: 10px; }

.noGps {
  border-top: 3px solid #272727e0 !important;
  border-radius: 10px; }

.fleet-list.on {
  border: none !important;
  border-radius: unset;
  border-left: 3px solid #6cc788 !important; }

.fleet-list.off {
  border: none !important;
  border-radius: unset;
  border-left: 3px solid #dc3545 !important; }

.fleet-list.idle {
  border: none !important;
  border-radius: unset;
  border-left: 3px solid #fcc100 !important; }

.fleet-list.noGps {
  border: none !important;
  border-radius: unset;
  border-left: 3px solid #272727e0 !important; }

.headerLink:hover, .headerLink:active, .headerLink:focus {
  background-color: #6cc788;
  color: #fff; }

.ctrlCntr:hover, .ctrlCntr:active, .ctrlCntr:focus {
  color: #6cc788 !important;
  cursor: pointer; }

.vCard .headerLink {
  -webkit-transition: background-color 0.175s cubic-bezier(0.4, 0, 0.2, 1), border 0.175s cubic-bezier(0.4, 0, 0.2, 1), color 0.175s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.175s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.175s cubic-bezier(0.4, 0, 0.2, 1);
  transition: background-color 0.175s cubic-bezier(0.4, 0, 0.2, 1), border 0.175s cubic-bezier(0.4, 0, 0.2, 1), color 0.175s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.175s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.175s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: background-color 0.175s cubic-bezier(0.4, 0, 0.2, 1), border 0.175s cubic-bezier(0.4, 0, 0.2, 1), color 0.175s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.175s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.175s cubic-bezier(0.4, 0, 0.2, 1);
  transition: background-color 0.175s cubic-bezier(0.4, 0, 0.2, 1), border 0.175s cubic-bezier(0.4, 0, 0.2, 1), color 0.175s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.175s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.175s cubic-bezier(0.4, 0, 0.2, 1);
  transition: background-color 0.175s cubic-bezier(0.4, 0, 0.2, 1), border 0.175s cubic-bezier(0.4, 0, 0.2, 1), color 0.175s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.175s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.175s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.175s cubic-bezier(0.4, 0, 0.2, 1); }

.headerLink:active .preview-arrow,
.headerLink:focus .preview-arrow,
.headerLink:hover .preview-arrow {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  color: rgba(255, 255, 255, 0.5); }

.preview-arrow {
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transform: translate(-200%, 0);
  -ms-transform: translate(-200%, 0);
  transform: translate(-200%, 0);
  position: absolute;
  top: 24%;
  right: 0;
  margin-top: -18px;
  color: transparent;
  font-size: 36px; }

.vCard .card-vehicle-summ {
  display: inline-flex;
  font-size: 13px; }

.vCard .card-vehicle-summ > i {
  font-size: 20px !important; }

.vCard span {
  font-weight: bold;
  word-wrap: break-word; }

.flip-container {
  perspective: 1000px; }

/* flip the pane when hovered */
.flip-container.flip .flipper {
  transform: rotateY(180deg); }

.flip-container, .front, .back {
  width: 100%;
  height: 100%; }

/* flip speed goes here */
.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative; }

/* hide back of pane during swap */
.front, .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0; }

/* front pane, placed above back */
.front {
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg); }

/* back, initially hidden pane */
.back {
  transform: rotateY(180deg); }

.export {
  font-size: 33px;
  color: #3c8dbc; }

/*................................................*/
/*..........Machine Info Nav..............*/
.subnav.machine-info-bar {
  width: 100%;
  height: 2.5rem;
  padding-left: 8rem; }

.subnav.machine-info-bar .machine-info-tabs {
  height: 100%; }

.subnav.machine-info-bar > .machine-info-nav > li > a {
  display: block;
  text-align: center; }

.subnav.machine-info-bar > .machine-info-nav > li > a > .nav-text {
  font-size: 1rem !important; }

.subnav.machine-info-bar > .machine-info-nav > li > a {
  height: 2.5rem; }

.subnav.machine-info-bar > .machine-info-tabs > li > a {
  border-left: 1px solid #dadada;
  width: 7.4rem !important;
  cursor: pointer;
  transition: all 0.3s ease;
  height: 100% !important;
  padding-left: 0.4rem !important;
  padding-right: 0.4rem !important; }

/*.........................................*/
/*.................Machine Dash..........*/
.machinedash-tracking .py-1 {
  padding-top: 0px !important; }

/*.............Machine Live Dash............*/
#Widget_Machine_Live_Dash {
  transition: bottom 0.5s 0s cubic-bezier(0.1, 1.2, 0.3, 1); }

#Widget_Machine_Live_Dash.minimize {
  bottom: -300px !important; }

#Widget_Machine_Live_Dash ul > li {
  align-items: center;
  display: flex; }

#Widget_Machine_Live_Dash ul > li > a {
  transform: rotate(-90deg); }

.minimize .minIcon {
  transform: rotate(180deg); }

.skewEdgeLeft > h5 > span > i {
  cursor: pointer; }

.skewEdgeLeft:before {
  z-index: -1;
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: -30px;
  display: block;
  width: 60px;
  background: white;
  -webkit-transform: skew(-10deg);
  -ms-transform: skew(-10deg);
  transform: skew(-39deg); }

/*.................................*/
/*........................................*/
/*.............Picklist..........*/
.picklist-group-item {
  position: relative;
  display: block;
  padding: 0.45rem 1.25rem;
  margin-bottom: -1px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.picklist-group > ul {
  width: 100%;
  padding: 0;
  height: 150px;
  overflow-y: auto; }

.picklist-selector {
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  list-style: none; }

.picklist-container {
  display: flex; }

.picklist-container > div:nth-child(1), .picklist-container > div:nth-child(3) {
  flex: 1; }

/* can ignore styles below; decorative only */
.picklist-container {
  border: 1px solid #ced4da; }

.picklist-group {
  height: 150px;
  width: 75px;
  border-right: 1px solid #ced4da;
  border-radius: 0.25rem;
  display: flex; }

.picklist-group:nth-child(2) {
  justify-content: center;
  align-items: center; }

.picklist-group:nth-child(3) {
  border-right: none; }

/*.................................*/
/*.................form-elements..........*/
/*...........slider............*/
input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: 13.8px 0; }

input[type=range]:focus {
  outline: none; }

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: #D3D3D3;
  border-radius: 100%;
  border: 0.2px solid #010101; }

input[type=range]::-webkit-slider-thumb {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14);
  height: 36px;
  width: 36px;
  border-radius: 100%;
  background: #D3D3D3;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px; }

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #D3D3D3; }

input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: #D3D3D3;
  border-radius: 100%;
  border: 0.2px solid #010101; }

input[type=range]::-moz-range-thumb {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14);
  height: 36px;
  width: 36px;
  border-radius: 100%;
  background: #D3D3D3;
  cursor: pointer; }

input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent; }

input[type=range]::-ms-fill-lower {
  background: #D3D3D3;
  border: 0.2px solid #010101;
  border-radius: 100%; }

input[type=range]::-ms-fill-upper {
  background: #D3D3D3;
  border: 0.2px solid #010101;
  border-radius: 100%; }

input[type=range]::-ms-thumb {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14);
  height: 36px;
  width: 36px;
  border-radius: 100%;
  background: #D3D3D3;
  cursor: pointer;
  height: 8.4px; }

input[type=range]:focus::-ms-fill-lower {
  background: #D3D3D3; }

input[type=range]:focus::-ms-fill-upper {
  background: #D3D3D3; }

/*..............................*/
/*............checkbox................*/
.slider-checkbox {
  position: relative; }

.slider-checkbox input {
  margin: 0px;
  margin-top: 1px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  background: red;
  width: 40px;
  height: 20px; }

.slider-checkbox input:checked + .label:before {
  background-color: #1877c1;
  padding-left: 6px; }

.slider-checkbox input:checked + .label:after {
  left: 21px; }

.slider-checkbox .label {
  position: relative;
  padding-left: 46px; }

.slider-checkbox .label:before, .slider-checkbox .label:after {
  position: absolute;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  transition: background-color 0.3s, left 0.3s; }

.slider-checkbox .label:before {
  content: "";
  color: #fff;
  box-sizing: border-box;
  padding-left: 23px;
  font-size: 12px;
  line-height: 20px;
  background-color: #888;
  left: 0px;
  top: 0px;
  height: 20px;
  width: 40px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px; }

.slider-checkbox .label:after {
  content: "";
  letter-spacing: 20px;
  background: #fff;
  left: 1px;
  top: 1px;
  height: 18px;
  width: 18px; }

.slider-checkbox {
  margin-bottom: 12px; }

/*.......................................*/
/*.............radio button.............*/
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px; }

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666; }

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid rgba(0, 123, 255, 0.5);
  border-radius: 100%;
  background: #fff; }

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #3c8dbc;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; }

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0); }

[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

/*.......................................*/
/*.......................................*/
/*...........Alert Feed .................*/
.ticker {
  padding-left: 0;
  padding-right: 0;
  border-radius: 3px;
  margin-top: 0;
  margin-bottom: 1rem;
  margin-left: -1.25rem;
  margin-right: -1.25rem; }

.ticker-item {
  display: block;
  position: relative;
  padding: 5px 16px; }

.ticker-body {
  margin-left: 45px;
  padding-left: 0.5rem;
  padding-top: 0.5rem; }

.tickerdiv img {
  width: auto;
  border: none;
  outline: 0;
  display: block; }

.ticker .tickerdiv:nth-child(even) {
  background-color: #f1f1f1 !important; }

.ticker .tickerdiv:nth-child(odd) {
  background-color: #ffffff !important; }

/*..............................*/
/*..............try................*/
.input_group {
  display: block;
  position: relative;
  width: 100%;
  min-height: 70px;
  margin-bottom: 10px; }

@media only screen and (max-width: 870px) {
  .input_group {
    min-height: 64px;
    margin-bottom: 0; } }

.input_group input.input {
  display: block;
  position: relative;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  width: 100%;
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -ms-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
  z-index: 1;
  -webkit-appearance: none;
  border: none;
  outline: none;
  padding: 18px 15px 0 15px;
  margin: 0 auto 13px auto;
  color: #363b4a;
  letter-spacing: .7px;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 16px;
  height: 70px; }

@media only screen and (min-width: 0px) and (max-width: 870px) {
  .input_group input.input {
    font-size: 14px;
    padding: 15px 15px 0 15px;
    height: 64px; } }

.input_group input.input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  color: #cacaca; }

.input_group i {
  top: 0.3rem;
  right: 15px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: 20px;
  height: 70px;
  z-index: 1000;
  position: absolute;
  z-index: 100; }

@media only screen and (min-width: 0px) and (max-width: 870px) {
  .input_group i {
    height: 64px; } }

.input_group i svg {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 20px;
  height: 20px; }

.input_group i.input_error_icon {
  z-index: 10; }

.input_group.input_error .form-control {
  border-color: #dc3545; }

.input_group i.input_error_icon path {
  fill: #f16767; }

.input_group i.input_valid_icon {
  z-index: 1; }

.input_group i.input_valid_icon path {
  fill: #50b87f; }

.input_group.input_error i.input_error_icon svg {
  opacity: 1; }

.input_group.input_valid i.input_error_icon {
  z-index: 1; }

.input_group.input_valid i.input_valid_icon {
  z-index: 10; }

.input_group.input_valid.input_hasValue.input_onChange i.input_valid_icon svg {
  opacity: 1; }

.input_group.input_focused i.input_hint_icon svg {
  opacity: 1; }

.input_group.input_onChange i.input_hint_icon svg {
  opacity: 0; }

.error_container {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 100%;
  white-space: nowrap;
  line-height: 70px;
  width: auto;
  height: 70px;
  background: #f16767;
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 20px 0 20px;
  color: white;
  font-size: 14px;
  font-weight: 100;
  letter-spacing: .6px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

@media only screen and (min-width: 0px) and (max-width: 870px) {
  .error_container {
    position: relative;
    top: -13px;
    left: 0;
    line-height: 30px;
    font-size: 12px;
    padding: 0 15px 0 15px;
    font-weight: 300; } }

.error_container.visible {
  -o-transform: translateX(0px);
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
  opacity: 1; }

@media only screen and (min-width: 0px) and (max-width: 870px) {
  .error_container.visible {
    height: 30px; } }

.error_container.invisible {
  -o-transform: translateX(-30px);
  -webkit-transform: translateX(-30px);
  -moz-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
  transform: translateX(-30px);
  opacity: 0;
  -webkit-animation-delay: 2s;
  /* Chrome, Safari, Opera */
  animation-delay: 2s; }

@media only screen and (min-width: 0px) and (max-width: 870px) {
  .error_container.invisible {
    height: 0;
    line-height: 0;
    -o-transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px); } }

.password_validator {
  top: 57px;
  left: 15%;
  position: absolute;
  display: block;
  opacity: 0;
  width: 320px;
  height: auto;
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  opacity: 1;
  z-index: 1500; }

.password_validator .validator_container {
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #283e4a;
  padding: 10px 0px 10px 20px; }

.password_validator .validator_container h4.validator_title {
  display: block;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin: 12px 0 5px 0;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 1.5px; }

.password_validator .validator_container h4.validator_title.valid {
  color: #50b87f;
  opacity: 1; }

.password_validator .validator_container ul.rules_list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0; }

.password_validator .validator_container ul.rules_list > li {
  display: block;
  position: relative;
  margin: 12px 0 12px 0;
  font-size: 12px;
  letter-spacing: .3px;
  font-weight: 200;
  color: #ffffff; }

.password_validator .validator_container ul.rules_list > li .bad_word {
  font-style: italic;
  padding: 0 5px 0 0; }

.password_validator .validator_container ul.rules_list > li .bad_word:after {
  content: ',';
  display: inline; }

.password_validator .validator_container ul.rules_list > li .bad_word:last-child {
  padding: 0; }

.password_validator .validator_container ul.rules_list > li .bad_word:last-child:after {
  display: none; }

.password_validator .validator_container ul.rules_list > li .icon_invalid, .password_validator .validator_container ul.rules_list > li .icon_hint {
  display: block;
  position: absolute;
  top: 1px;
  left: 0;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  width: 18px;
  height: 18px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.password_validator .validator_container ul.rules_list > li .icon_invalid svg, .password_validator .validator_container ul.rules_list > li .icon_hint svg {
  opacity: 1;
  width: 18px;
  height: 18px; }

.password_validator .validator_container ul.rules_list > li .icon_invalid svg path {
  fill: #f16767; }

.password_validator .validator_container ul.rules_list > li .icon_valid {
  position: absolute;
  top: 1px;
  left: 0;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  width: 19px;
  height: 19px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.password_validator .validator_container ul.rules_list > li .icon_valid svg {
  opacity: 1;
  width: 19px;
  height: 19px; }

.password_validator .validator_container ul.rules_list > li .error_message {
  display: block;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  padding: 0 0 0 27px;
  opacity: .9; }

.password_validator .validator_container ul.rules_list > li.valid .icon_invalid {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  opacity: 0; }

.password_validator .validator_container ul.rules_list > li.valid .icon_valid {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

.password_validator .validator_container ul.rules_list > li.valid .error_message {
  opacity: .5; }

.password_validator.visible {
  -o-transform: translateX(0px);
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
  opacity: 1; }

.password_validator.invisible {
  -o-transform: translateX(-15px);
  -webkit-transform: translateX(-15px);
  -moz-transform: translateX(-15px);
  -ms-transform: translateX(-15px);
  transform: translateX(-15px);
  opacity: 0;
  -webkit-animation-delay: 2s;
  /* Chrome, Safari, Opera */
  animation-delay: 2s; }

.Select {
  position: relative;
  margin-bottom: 10px;
  min-height: 50px; }

.Select input::-webkit-contacts-auto-fill-button,
.Select input::-webkit-credentials-auto-fill-button {
  display: none !important; }

.Select input::-ms-clear {
  display: none !important; }

.Select input::-ms-reveal {
  display: none !important; }

.Select,
.Select div,
.Select input,
.Select span {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.Select.is-disabled .Select-arrow-zone {
  cursor: default;
  pointer-events: none;
  opacity: 0.35; }

.Select.is-disabled > .Select-control {
  background-color: #f9f9f9; }

.Select.is-disabled > .Select-control:hover {
  box-shadow: none; }

.Select.is-open > .Select-control {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: #fff;
  border-color: #b3b3b3 #ccc #d9d9d9; }

.Select.is-open > .Select-control .Select-arrow {
  top: -2px;
  border-color: transparent transparent #999;
  border-width: 0 5px 5px; }

.Select.is-searchable.is-open > .Select-control {
  cursor: text; }

.Select.is-searchable.is-focused:not(.is-open) > .Select-control {
  cursor: text; }

.Select.is-focused > .Select-control {
  background: #fff; }

.Select.is-focused:not(.is-open) > .Select-control {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
  background: #fff; }

.Select.has-value.is-clearable.Select--single > .Select-control .Select-value {
  padding-right: 42px; }

.Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: #333; }

.Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label,
.Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label {
  cursor: pointer;
  text-decoration: none; }

.Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
.Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
  color: #007eff;
  outline: none;
  text-decoration: underline; }

.Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
.Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
  background: #fff; }

.Select.has-value.is-pseudo-focused .Select-input {
  opacity: 0; }

.Select.is-open .Select-arrow,
.Select .Select-arrow-zone:hover > .Select-arrow {
  border-top-color: #666; }

.Select.Select--rtl {
  direction: rtl;
  text-align: right; }

.Select-control {
  background-color: #fff;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  height: 1.95rem;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%; }

.Select-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06); }

.Select-control .Select-input:focus {
  outline: none;
  background: #fff; }

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  bottom: 0;
  color: #aaa;
  left: 0;
  line-height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.Select-input {
  height: 1.95rem;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle; }

.Select-input > input {
  width: 100%;
  background: none transparent;
  border: 0 none;
  box-shadow: none;
  cursor: default;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: none;
  line-height: 17px;
  /* For IE 8 compatibility */
  padding: 8px 0 12px;
  /* For IE 8 compatibility */
  -webkit-appearance: none; }

.is-focused .Select-input > input {
  cursor: text; }

.has-value.is-pseudo-focused .Select-input {
  opacity: 0; }

.Select-control:not(.is-searchable) > .Select-input {
  outline: none; }

.Select-loading-zone {
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 16px; }

.Select-loading {
  -webkit-animation: Select-animation-spin 400ms infinite linear;
  -o-animation: Select-animation-spin 400ms infinite linear;
  animation: Select-animation-spin 400ms infinite linear;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-right-color: #333;
  display: inline-block;
  position: relative;
  vertical-align: middle; }

.Select-clear-zone {
  -webkit-animation: Select-animation-fadeIn 200ms;
  -o-animation: Select-animation-fadeIn 200ms;
  animation: Select-animation-fadeIn 200ms;
  color: #999;
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 17px; }

.Select-clear-zone:hover {
  color: #D0021B; }

.Select-clear {
  display: inline-block;
  font-size: 18px;
  line-height: 1; }

.Select--multi .Select-clear-zone {
  width: 17px; }

.Select-arrow-zone {
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 25px;
  padding-right: 5px; }

.Select--rtl .Select-arrow-zone {
  padding-right: 0;
  padding-left: 5px; }

.Select-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
  display: inline-block;
  height: 0;
  width: 0;
  position: relative; }

.Select-control > *:last-child {
  padding-right: 5px; }

.Select--multi .Select-multi-value-wrapper {
  display: inline-block; }

.Select .Select-aria-only {
  position: absolute;
  display: inline-block;
  height: 1px;
  width: 1px;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  float: left; }

@-webkit-keyframes Select-animation-fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes Select-animation-fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.Select-menu-outer {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top-color: #e6e6e6;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;
  -webkit-overflow-scrolling: touch; }

.Select-menu {
  max-height: 198px;
  overflow-y: auto; }

.Select-option {
  box-sizing: border-box;
  background-color: #fff;
  color: #666666;
  cursor: pointer;
  display: block;
  padding: 8px 10px; }

.Select-option:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }

.Select-option.is-selected {
  background-color: #f5faff;
  /* Fallback color for IE 8 */
  background-color: rgba(0, 126, 255, 0.04);
  color: #333; }

.Select-option.is-focused {
  background-color: #ebf5ff;
  /* Fallback color for IE 8 */
  background-color: rgba(0, 126, 255, 0.08);
  color: #333; }

.Select-option.is-disabled {
  color: #cccccc;
  cursor: default; }

.Select-noresults {
  box-sizing: border-box;
  color: #999999;
  cursor: default;
  display: block;
  padding: 8px 10px; }

.Select--multi .Select-input {
  vertical-align: middle;
  margin-left: 10px;
  padding: 0; }

.Select--multi.Select--rtl .Select-input {
  margin-left: 0;
  margin-right: 10px; }

.Select--multi.has-value .Select-input {
  margin-left: 5px; }

.Select--multi .Select-value {
  background-color: #ebf5ff;
  /* Fallback color for IE 8 */
  background-color: rgba(0, 126, 255, 0.08);
  border-radius: 2px;
  border: 1px solid #c2e0ff;
  /* Fallback color for IE 8 */
  border: 1px solid rgba(0, 126, 255, 0.24);
  color: #007eff;
  display: inline-block;
  font-size: 0.9em;
  line-height: 1.7;
  margin-left: 5px;
  margin-top: 5px;
  vertical-align: top; }

.Select--multi .Select-value-icon,
.Select--multi .Select-value-label {
  display: inline-block;
  vertical-align: middle; }

.Select--multi .Select-value-label {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  cursor: default;
  padding: 2px 5px; }

.Select--multi a.Select-value-label {
  color: #007eff;
  cursor: pointer;
  text-decoration: none; }

.Select--multi a.Select-value-label:hover {
  text-decoration: underline; }

.Select--multi .Select-value-icon {
  cursor: pointer;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  border-right: 1px solid #c2e0ff;
  /* Fallback color for IE 8 */
  border-right: 1px solid rgba(0, 126, 255, 0.24);
  padding: 1px 5px 3px; }

.Select--multi .Select-value-icon:hover,
.Select--multi .Select-value-icon:focus {
  background-color: #d8eafd;
  /* Fallback color for IE 8 */
  background-color: rgba(0, 113, 230, 0.08);
  color: #0071e6; }

.Select--multi .Select-value-icon:active {
  background-color: #c2e0ff;
  /* Fallback color for IE 8 */
  background-color: rgba(0, 126, 255, 0.24); }

.Select--multi.Select--rtl .Select-value {
  margin-left: 0;
  margin-right: 5px; }

.Select--multi.Select--rtl .Select-value-icon {
  border-right: none;
  border-left: 1px solid #c2e0ff;
  /* Fallback color for IE 8 */
  border-left: 1px solid rgba(0, 126, 255, 0.24); }

.Select--multi.is-disabled .Select-value {
  background-color: #fcfcfc;
  border: 1px solid #e3e3e3;
  color: #333; }

.Select--multi.is-disabled .Select-value-icon {
  cursor: not-allowed;
  border-right: 1px solid #e3e3e3; }

.Select--multi.is-disabled .Select-value-icon:hover,
.Select--multi.is-disabled .Select-value-icon:focus,
.Select--multi.is-disabled .Select-value-icon:active {
  background-color: #fcfcfc; }

@keyframes Select-animation-spin {
  to {
    transform: rotate(1turn); } }

@-webkit-keyframes Select-animation-spin {
  to {
    -webkit-transform: rotate(1turn); } }

.button {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  display: inline-block;
  position: relative;
  border: none;
  border-radius: 3px;
  background: #545a6a;
  color: #ffffff;
  font: normal normal 200 13px/40px 'Poppins', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  height: 40px;
  padding: 0px 15px;
  -webkit-font-smoothing: subpixel-antialiased;
  cursor: pointer;
  white-space: nowrap; }

.button.button_wide {
  display: block;
  position: relative;
  width: 100%;
  margin: 20px 0 0 0;
  height: 50px;
  font-size: 13px; }

.button:hover,
.button:focus {
  color: #ffffff;
  background: #40b4de; }

.button:active,
.button.active {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: #6bc5e6; }

/*.............................................*/
/*.................HighCharts....................*/
.highChartsContainer {
  position: relative;
  flex-grow: 1; }

.highChartGrph {
  position: absolute;
  left: 0;
  top: 50px;
  bottom: 0;
  right: 0;
  overflow: hidden; }

/*............................................*/
/*.......................Chips..................*/
.md-chip {
  display: inline-block;
  background: #e0e0e0;
  padding: 0 12px;
  border-radius: 5px;
  font-size: 0.8rem; }

.md-chip-clickable {
  cursor: pointer; }

.md-chip, .md-chip-icon {
  height: 1.9rem;
  line-height: 1.9rem; }

@media only screen and (min-width: 1246px) {
  .md-chip, .md-chip-icon {
    width: 13rem; } }

.md-chip-icon {
  display: block;
  float: left;
  width: 32px;
  border-radius: 5px;
  text-align: center;
  color: white;
  margin: 0 8px 0 -12px; }

.md-chips {
  padding: 5px 0; }

.md-chips .md-chip {
  margin: 0 5px 3px 0; }

.md-chip-raised {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px -2px rgba(0, 0, 0, 0.2); }

/*.................................................*/
/*..............POI GEO........*/
.locations-scroll li:nth-of-type(odd) {
  background: #f2f2f2; }

.locations-scroll li:nth-of-type(even) {
  background: #ffffff; }

.assign-scroll li:nth-of-type(odd) {
  background: #f2f2f2; }

.assign-scroll li:nth-of-type(even) {
  background: #ffffff; }

.poi-list.selected, .geo-list.selected {
  background-color: #c4e8ed !important; }

#mapbutton > .btn-square {
  width: 40px;
  height: 40px;
  cursor: pointer; }

/*.....................*/
/*......................Wizard Model................*/
.wizard-progress {
  display: table;
  width: 100%;
  table-layout: fixed;
  position: relative; }

.wizard-progress .step {
  display: table-cell;
  text-align: center;
  vertical-align: top;
  overflow: visible;
  position: relative;
  font-size: 14px;
  color: #464a4e;
  font-weight: bold; }

.wizard-progress .step:not(:last-child):before {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 37px;
  background-color: #f2f2f2;
  height: 6px;
  width: 100%; }

.wizard-progress .step .node {
  display: inline-block;
  border: 1px solid #f2f2f2;
  background-color: #b8d9ea;
  height: 35px;
  width: 35px;
  position: absolute;
  top: 21px;
  left: 50%;
  margin-left: -18px;
  border-radius: 50px;
  color: black;
  padding: 6px; }

.wizard-progress .step.complete:before {
  background-color: #6cc788; }

.wizard-progress .step.complete.in-progress:before {
  background-color: #f2f2f2; }

.wizard-progress .step.complete .node {
  border-color: #6cc788;
  background-color: #6cc788; }

.wizard-progress .step.complete .node:before {
  content: '';
  background-color: #6cc788; }

.wizard-progress .step.in-progress:after {
  background: #6cc788;
  background: -moz-linear-gradient(left, #6cc788 0%, #f2f2f2 100%);
  background: -webkit-linear-gradient(left, #6cc788 0%, #f2f2f2 100%);
  background: linear-gradient(to right, #6cc788 0%, #f2f2f2 100%); }

.wizard-progress .step.in-progress .node {
  border-color: transparent;
  background-color: #1b475f;
  color: white; }

.form-wizard {
  height: 500px; }

.form-scroll-notification > div:last-child {
  padding-bottom: 60% !important; }

.form-scroll-signal > div:last-child {
  padding-bottom: 100px !important; }

@media only screen and (min-height: 940px) {
  .form-scroll-notification {
    height: 100% !important; }
  .form-scroll-signal {
    height: 80% !important; } }

.form-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 10px;
  padding-right: 10px;
  flex-grow: 1;
  min-height: 0; }

.form-scroll-notification {
  height: 80vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden; }

.form-scroll-signal {
  height: 70vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden; }

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold; }

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer; }

/* Add Animation */
@-webkit-keyframes wizardSlideIn {
  from {
    right: -300px;
    opacity: 0; }
  to {
    right: 0;
    opacity: 1; } }

@keyframes wizardSlideIn {
  from {
    right: -300px;
    opacity: 0; }
  to {
    right: 0;
    opacity: 1; } }

@-webkit-keyframes wizardFadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes wizardFadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/*..................................................*/
.box {
  width: 400px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  height: 150px;
  top: 40%;
  left: 40%;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

.toast {
  align-items: flex-start;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  padding: 16px;
  background-color: #283e4a;
  background-color: #283e4af2;
  border-left: 3px solid tomato; }

.toast:not(:last-child) {
  margin: 0 0 12px; }

.toast__content {
  flex: 1 1 auto;
  margin: 0 12px 0 0;
  overflow: hidden;
  text-overflow: ellipsis; }

.toast__dismiss {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: block;
  flex: 0 0 auto;
  font: inherit;
  padding: 0; }

.toasts {
  top: 74px;
  position: fixed;
  right: 24px;
  width: 340px;
  z-index: 1500; }

/*.......................................*/
/*.........Pagination...................*/
.page-link {
  color: #6cc788;
  border: 1px solid #6cc788;
  background-color: transparent;
  border-radius: 2rem;
  margin-left: 10px;
  cursor: pointer; }

.page-item:last-child .page-link {
  width: 90px;
  text-align: center;
  cursor: pointer; }

.page-item:first-child .page-link {
  width: 90px;
  text-align: center;
  cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 5.8rem !important;
  border-bottom-left-radius: 5.8rem !important;
  cursor: pointer; }

.page-item:last-child .page-link {
  border-top-right-radius: 5.8rem !important;
  border-bottom-right-radius: 5.8rem !important;
  cursor: pointer; }

/*.............................*/
.form-mach-search input {
  display: block;
  width: 100%;
  height: 50%;
  padding: .15rem .75rem;
  font-size: 1rem;
  line-height: 1.7;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }

.form-mach-search div {
  display: block !important; }

#description {
  font-size: 15px;
  font-weight: 300; }

#infowindow-content .title {
  font-weight: bold; }

#infowindow-content {
  display: none; }

#map #infowindow-content {
  display: inline; }

/*........................Map..................*/
/*.....................................*/
#pac-card {
  width: 100%;
  height: 40px; }

#pac-card.trackingCard {
  width: 36% !important;
  height: 44px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14);
  border-radius: 7px; }

.pac-card.trackingCard {
  padding: 0.4rem 0.75rem;
  width: 36% !important;
  height: 44px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14);
  border-radius: 7px; }

.button_assign {
  background-color: #17a2b8 !important;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 48px;
  padding: 3px;
  margin-left: 35px; }

#pac-container {
  padding-bottom: 12px;
  margin-right: 12px; }

.pac-container {
  z-index: 1500 !important; }

.pac-controls {
  display: inline-block;
  padding: 5px 11px; }

.pac-controls label {
  font-family: Poppins; }

.pac-container {
  z-index: 1500� !important; }

#pac-input {
  width: 76%; }

#pac-input:focus {
  border-color: #4d90fe; }

.sidebar-header {
  font-size: 16px;
  padding: 10px;
  text-transform: uppercase;
  background-color: #f4f4f4;
  color: #353535; }

/*.............Machine Status................*/
.badge-status {
  width: 30px;
  height: 30px;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; }

.highlightFilter {
  border-bottom: 3px solid #00f0fc !important;
  border-top: 3px solid #00f0fc !important; }

#mapMStatusSelect > .list-group {
  flex-direction: row; }

#mapMStatusSelect > .list-group > .list-group-item:last-child {
  margin-bottom: -1px;
  border-radius: 0px;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important; }

#mapMStatusSelect > .list-group > .list-group-item:first-child {
  background: #17a2b8;
  border-radius: 0px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px; }

#mapMStatusSelect > .list-group > .list-group-item {
  position: relative;
  display: block;
  padding: 0.4rem 0.75rem;
  margin-bottom: -1px;
  width: 200px;
  background-color: #283e4a;
  background-color: #283e4af2;
  color: white;
  font-size: 0.8rem;
  cursor: pointer;
  justify-content: space-evenly;
  border: 1px solid rgba(0, 0, 0, 0.125); }

@media only screen and (max-width: 1200px) {
  #mapMStatusSelect > .list-group > .list-group-item {
    padding: 0.3rem 1rem; } }

.tracking {
  width: 125px !important; }

.tracking-left {
  left: 50px !important; }

/*.....................................*/
/*..............Tracking................*/
#trackingMenu .list-group-flush > .list-group-item:nth-of-type(even) {
  background: #ffffff; }

#trackingMenu .list-group-flush > .list-group-item:nth-of-type(odd) {
  background: #f2f2f2; }

#trackingMenu .bar-left + .bar-body {
  margin-left: 60px;
  padding-left: 0.5rem; }

@media only screen and (max-width: 1341px) {
  #trackingMenu .nav > .nav-item {
    width: 1rem; } }

.widget.card .tracking-page {
  position: absolute;
  width: 100%; }

.widget.card .tracking-page #near-dlr {
  display: none !important; }

/*.................................*/
.widget-header {
  color: #353535;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-left: 3px solid #E58E1A;
  justify-content: center;
  padding-left: 10px; }

.config_settings .widget-header {
  flex-direction: row; }

.widget-header > span {
  display: block;
  margin-left: 0 !important; }

.path-text {
  font-family: FontAwesome;
  fill: black; }

.bs-tiny.tracking {
  font-size: 0.73rem !important; }

.bs-tiny.cursor {
  cursor: pointer; }

.row.vehicle-card:hover {
  background-color: #c4e8ed !important; }

.row.vehicle-card.selected {
  background-color: #c4e8ed !important; }

.material-icons.tracking.service {
  color: grey;
  font-size: 15px;
  cursor: pointer; }

.material-icons.tracking.service:hover {
  border-radius: 50px;
  background-color: lightgray; }

.material-icons.details {
  font-size: 16px !important; }

.vehicle-card-loader {
  padding-top: 200px;
  padding-left: -37px; }

.single-track-header {
  font-size: larger !important;
  padding-left: 0.85rem;
  font-weight: 400; }

.forget-password {
  color: blue;
  padding-bottom: 0.25rem; }

.forget-password:hover {
  text-decoration: underline;
  cursor: pointer; }

.reset-password.item-header {
  border-bottom: 1px solid black;
  padding-bottom: 10px; }

.textarea {
  overflow: auto;
  resize: none; }

.alert-tab {
  padding: "4px 12px";
  cursor: pointer; }

.alert-tab:hover {
  background-color: #c4e8ed !important; }

.near-veh {
  width: 35% !important; }

.near-left {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  width: 400px !important; }

.nearest-side-bar {
  display: none;
  height: 60vh;
  overflow-y: auto; }

.nearest-card-body {
  text-transform: uppercase; }

.replay-tab {
  padding: 5px;
  cursor: pointer; }

.replay-tab:hover {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  background-color: #c4e8ed !important; }

.track-filter {
  width: 100%; }

.veh-card-click {
  cursor: pointer; }

.replay-color {
  color: white; }

.tab-scroll {
  height: 58vh;
  overflow-y: auto;
  overflow-x: hidden; }

/*..........Contact..............*/
.contact-info-tabs a {
  border: 1px solid #dadada;
  width: 8rem !important;
  cursor: pointer;
  border-radius: 9px;
  height: 2.4rem !important;
  padding: 0.4rem !important; }

/*.........................................*/
/*...............Date Time Picker By React...........*/
.rdt {
  position: relative; }

.rdtPicker {
  display: none;
  position: absolute;
  width: 295px;
  padding: 20px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f9f9f9; }

.rdtOpen .rdtPicker {
  display: block; }

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static; }

.rdtPicker .rdtTimeToggle {
  text-align: center; }

.rdtPicker table {
  width: 100%;
  margin: 0; }

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px; }

.rdtPicker td {
  cursor: pointer; }

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer; }

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999; }

.rdtPicker td.rdtToday {
  position: relative; }

.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px; }

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff; }

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker td span.rdtOld {
  color: #999999; }

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker th {
  border-bottom: 1px solid #f9f9f9; }

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none; }

.rdtPicker th.rdtSwitch {
  width: 100px; }

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top; }

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker thead tr:first-child th {
  cursor: pointer; }

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee; }

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9; }

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer; }

.rdtPicker button:hover {
  background-color: #eee; }

.rdtPicker thead button {
  width: 100%;
  height: 100%; }

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer; }

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee; }

.rdtCounters {
  display: inline-block; }

.rdtCounters > div {
  float: left; }

.rdtCounter {
  height: 100px; }

.rdtCounter {
  width: 40px; }

.rdtCounterSeparator {
  line-height: 100px; }

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtCounter .rdtBtn:hover {
  background: #eee; }

.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em; }

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px; }

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px; }

/*..................................*/
/*..............Themer.............*/
.outer-color-band {
  width: 100%;
  height: 50px; }

.outer-color-band .color-band-item {
  float: left;
  width: 8.33%;
  height: 50px; }

.outer-color-band .clearer {
  height: 1px;
  overflow: hidden;
  clear: both;
  margin-top: -1px; }

.checkmark-color-band {
  display: inline-block;
  width: 22px;
  height: 22px;
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  position: absolute; }

.checkmark-color-band .checkmark_circle {
  position: absolute;
  width: 22px;
  height: 22px;
  background-color: green;
  border-radius: 11px;
  left: 0;
  top: 0; }

.checkmark-color-band .checkmark_stem {
  position: absolute;
  width: 3px;
  height: 9px;
  background-color: #fff;
  left: 11px;
  top: 6px; }

.checkmark-color-band .checkmark_kick {
  position: absolute;
  width: 3px;
  height: 3px;
  background-color: #fff;
  left: 8px;
  top: 12px; }

/*.................................*/
/*............font-resizer.......*/
.font-resizer {
  position: fixed;
  right: 10px;
  bottom: 1rem;
  z-index: 5; }

.font-resizer i {
  font-size: 10px !important; }

.font-resizer.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important; }

.font-resizer.btn-group > .btn:last-child:not(:first-child):not(.dropdown-toggle) {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important; }

.font-resizer.btn-group .btn {
  width: 10px !important;
  border-radius: 0 !important;
  margin-right: 0 !important;
  min-width: 5px !important; }

/*.....................................*/
/*.............DeviceRegistration............*/
#deviceRegistration-page .admin-list-item > div:not(:last-child) span, .reportTable .admin-list-item > div:not(:last-child) span,
#fleetManagement-page .admin-list-item > div:not(:last-child) span, .reportTable .admin-list-item > div:not(:last-child) span,
#userManagement-page .admin-list-item > div:not(:last-child) span, .reportTable .admin-list-item > div:not(:last-child) span,
#alerts .admin-list-item > div:not(:last-child) span {
  word-break: break-all;
  white-space: -moz-pre-wrap;
  /* Mozilla */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 */
  word-wrap: break-word;
  /* IE */ }

#alerts .admin-list-item, #deviceRegistration-page .admin-list-item, #userManagement-page .admin-list-item, .reportTable .admin-list-item, #fleetManagement-page .admin-list-item {
  height: unset;
  min-height: 3rem; }

/*...............*/
input[type="number"] {
  -moz-appearance: textfield; }

/*............LazyImg..........*/
.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.image-preload {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover; }

.image-loaded {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 1s ease; }

.image-fade-in {
  opacity: 1; }

/*..........................*/
/*........Animations................*/
.slideUpAnim {
  animation: slideUpAnim 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97);
  transform: translate3d(0, 0, 0); }

@keyframes slideUpAnim {
  0% {
    opacity: 0;
    transform: none; }
  100% {
    opacity: 1;
    transform: translate3d(0, -100%, 0); } }

.input_group.input_error.input_unfocused {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both; }

@keyframes shake {
  10%, 90% {
    left: -1px; }
  20%, 80% {
    left: 2px; }
  30%, 50%, 70% {
    left: -4px; }
  40%, 60% {
    left: 4px; } }

.bounceAnimX:hover {
  animation-name: bounceAlphaX;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

@keyframes bounceAlphaX {
  0% {
    transform: translateX(0px) scale(1); }
  100% {
    transform: translateX(-10px) scale(1); } }

.bounceAnimY:hover {
  animation-name: bounceAlphaY;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

.minimize .bounceAnimY:hover {
  animation-name: unset; }

@keyframes bounceAlphaY {
  0% {
    transform: translateY(0px) scale(1); }
  100% {
    transform: translateY(5px) scale(1); } }

/*......................................*/
.scroll-arrow button:hover {
  opacity: 1 !important;
  background: #353535 !important; }

/*......................................*/
.searchInput {
  -webkit-transition: width 0.4s;
  transition: width .4s;
  display: inline-block;
  width: 0px;
  font-family: "Aerial";
  font-weight: 100;
  z-index: 3;
  border: none;
  outline: none;
  color: #555;
  background: none;
  padding: 3px; }

.searchInputExpanded {
  -webkit-transition: width 0.4s;
  transition: width .4s;
  width: 295px;
  height: 30px;
  display: inline-block;
  border-bottom: 1px solid #BBB;
  background: #283e4a;
  color: white;
  cursor: text;
  z-index: 2;
  opacity: 0.9; }

.searchInputExpanded:focus {
  background: #283e4a;
  color: white; }

.searchInputExpanded::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: white; }

.searchInputExpanded:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: white;
  opacity: 1; }

.searchInputExpanded::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: white;
  opacity: 1; }

.searchInputExpanded:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white; }

/*.............Page Layout................*/
.split-screen {
  display: flex;
  height: 100%; }

.split-screen > div {
  transition: max-width 0.3s,flex 0.3s; }

.split-screen > div.collapsed, .split-screen > .admin-page.collapsed {
  flex: 0;
  max-width: 0px; }

.split-screen-50 > div {
  flex: 1; }

div[class*="split-screen-"] > div:nth-child(2) {
  margin-left: 0.3rem !important; }

div[class*="split-screen-"] > div.admin-page:nth-child(2) {
  padding-left: 0px !important; }

.split-screen-75 > div:nth-child(1) {
  flex: 2; }

.split-screen-75 > div:nth-child(2) {
  flex: 1; }

.closeBtn {
  color: #bd2130 !important; }

.closeBtn:hover {
  background-color: #bd2130 !important;
  color: #fff !important; }

/*..................................*/
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: transparent; }

.service-schedule .input_group, #service .input_group {
  min-height: unset !important;
  margin-bottom: unset !important; }

.service-schedule .admin-theader.primary-row, #service .admin-theader.primary-row {
  background-color: #d6d6d6; }

.border-right.border-white {
  border-right: 2px solid white; }

.product-table {
  height: 50% !important; }

.select-top .Select-menu-outer {
  top: auto;
  bottom: 100%; }

#reportTab .Select-menu-outer {
  margin-top: -15px !important;
  width: 89% !important; }

.signal-assigned-table thead th {
  background: #D3D3D3;
  border-right: 2px solid #e9ecef; }

.signal-assigned-table label {
  display: none; }

.signal-assigned-table tbody tr td {
  vertical-align: middle; }

.signal-assigned-table tbody tr td .input_group {
  min-height: 5%; }

.signal-assigned-table thead th {
  background: #D3D3D3;
  border-right: 2px solid #e9ecef; }

.signal-assigned-table tbody tr td {
  vertical-align: middle; }

.signal-assigned-table tbody tr td .input_group {
  min-height: 5%; }

#softwareDiv {
  height: 100%; }

/* flip the pane when hovered */
.flip-container.flip-dash .back-dash {
  transform: scaleY(1); }

.flip-container.flip-dash .front-dash {
  transform: scaleY(0); }

.front-dash, .back-dash {
  width: 100%;
  height: 100%; }

/* flip speed goes here */
.flipper-dash {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative; }

/* hide back of pane during swap */
.front-dash, .back-dash {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0; }

/* front pane, placed above back */
.front-dash {
  z-index: 2; }

/* back, initially hidden pane */
.back-dash {
  transform: scaleY(0); }

/*..............................Fuel Level ...............*/
#fuel_level_widget {
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
  display: flex; }

#fuel_level {
  display: flex;
  width: 35px;
  height: 200px;
  flex: 1;
  position: relative;
  background: #ddd;
  overflow: hidden; }

#fuel_level .before {
  display: block;
  width: 100%;
  border-right: 25px solid #fff;
  border-top: 200px solid transparent;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2; }

#fuel_level_reading {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  background: #343a40;
  height: 100%; }

#fuel_level .grid {
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  bottom: 0;
  z-index: 1;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly; }

#fuel_level .grid > .gridline {
  height: 1px;
  width: 100%;
  border-top: 2px solid #ddd;
  flex: 1; }

/* Safari 4.0 - 8.0 */
@-webkit-keyframes blinkmeter {
  0% {
    background: transparent; }
  49% {
    background: transparent; }
  50% {
    background: "#343a40"; } }

@keyframes blinkmeter {
  0% {
    background: transparent; }
  49% {
    background: transparent; }
  50% {
    background: "#343a40"; } }

#fuel_level_reading.full {
  height: 100%;
  background: #6cc788; }

#fuel_level_reading.empty.blink {
  height: 10%;
  background: #dc3545;
  -webkit-animation: blinkmeter 700ms infinite;
  /* Safari 4.0 - 8.0 */
  animation: blinkmeter 1s infinite; }

#fuel_level_reading.half {
  height: 50%;
  background: #FFA500; }

#fuel_level_reading.stableFuel {
  height: 75%;
  background: #6cc788; }

#fuel_level_reading.reserve.blink {
  height: 20%;
  background: #dc3545;
  -webkit-animation: blinkmeter 700ms infinite;
  /* Safari 4.0 - 8.0 */
  animation: blinkmeter 1s infinite; }

#fuel_level_reading.error.blink {
  height: 100%;
  background: #000;
  -webkit-animation: blinkmeter 700ms infinite;
  /* Safari 4.0 - 8.0 */
  animation: blinkmeter 1s infinite; }

.admin_search {
  position: relative; }

.admin_search > .admin_search_btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50px;
  align-items: center;
  justify-content: center; }

/*............................Login Page........*/
.neutral_theme {
  background-image: linear-gradient(to right top, #051937, #171228, #190a1a, #12040d, #000000); }

.neutral_theme .input_group .control-label {
  display: none; }

.neutral_theme .input_group {
  min-height: 40px; }

.neutral_theme .input_group input {
  background: #18091896;
  border-width: 2px;
  color: white; }

.neutral_theme .input_group i {
  height: 30px !important; }

.neutral_theme #questionSelect {
  margin-bottom: 15px;
  background: #18091896;
  border-width: 2px;
  color: white; }

.neutral_theme .brand-icon {
  transition: all 200ms;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0px 5px 28px 5px rgba(0, 0, 0, 0.2); }

.neutral_theme .brand-icon:hover {
  transform: scale(1.3); }

.pulse-animation {
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: pulse 2s infinite;
  background: #ffffff14; }

.brand-component {
  transition: all  1s; }

.brand-component .brand-list {
  transition: flex 1s;
  animation: brandfadein 3s forwards; }

.neutral_theme .d-vanish {
  display: none !important;
  flex: 0 !important; }

@keyframes brandfadein {
  0% {
    flex: 0;
    opacity: 0;
    min-width: 0; }
  20% {
    flex: 0;
    opacity: 0;
    min-width: 0; }
  40% {
    flex: 1;
    height: 60%;
    opacity: 0;
    min-width: 400px; }
  60% {
    flex: 1;
    height: 60%;
    opacity: 0.5; }
  100% {
    flex: 1;
    height: 100%;
    opacity: 1; } }

@-webkit-keyframes brandfadein {
  0% {
    flex: 0;
    opacity: 0;
    min-width: 0; }
  20% {
    flex: 0;
    opacity: 0;
    min-width: 0; }
  40% {
    flex: 1;
    height: 60%;
    opacity: 0;
    min-width: 400px; }
  60% {
    flex: 1;
    height: 60%;
    opacity: 0.5; }
  100% {
    flex: 1;
    height: 100%;
    opacity: 1; } }

@-o-keyframes brandfadein {
  0% {
    flex: 0;
    opacity: 0;
    min-width: 0; }
  20% {
    flex: 0;
    opacity: 0;
    min-width: 0; }
  40% {
    flex: 1;
    height: 60%;
    opacity: 0;
    min-width: 400px; }
  60% {
    flex: 1;
    height: 60%;
    opacity: 0.5; }
  100% {
    flex: 1;
    height: 100%;
    opacity: 1; } }

@-moz-keyframes brandfadein {
  0% {
    flex: 0;
    opacity: 0;
    min-width: 0; }
  20% {
    flex: 0;
    opacity: 0;
    min-width: 0; }
  40% {
    flex: 1;
    height: 60%;
    opacity: 0;
    min-width: 400px; }
  60% {
    flex: 1;
    height: 60%;
    opacity: 0.5; }
  100% {
    flex: 1;
    height: 100%;
    opacity: 1; } }

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4); }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

.cover {
  /* background: #8e9eab !important;  /* fallback for old browsers
background: -webkit-linear-gradient(to right, #eef2f3, #8e9eab);
background: linear-gradient(to right, #eef2f3, #8e9eab);   */
  background-color: black !important;
  background-position: center center;
  overflow: hidden; }

.cover .btn.btn-success, .cover .btn.btn-info {
  background-color: #ab293b !important; }

.brandAnim {
  overflow: hidden;
  height: auto;
  max-height: 0px;
  animation-delay: 500ms;
  -webkit-animation-name: expandBrand;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  animation-name: expandBrand;
  animation-duration: 1s;
  animation-fill-mode: forwards; }

@-webkit-keyframes expandBrand {
  from {
    max-height: 0; }
  to {
    max-height: 400px; } }

/* Standard syntax */
@keyframes expandBrand {
  from {
    max-height: 0; }
  to {
    max-height: 400px; } }

/*............Login Styles..................*/
.overview_box {
  width: 240px;
  height: 60px;
  display: flex;
  flex-direction: column;
  position: relative; }

.text {
  font-family: 'Lato', sans-serif;
  color: #fff;
  font-weight: 300;
  font-size: 16px;
  order: 2;
  oapcity: 1;
  -webkit-animation: fade-in-out 2.5s infinite;
  -moz-animation: fade-in-out 2.5s infinite;
  -o-animation: fade-in-out 2.5s infinite;
  animation: fade-in-out 2.5s infinite; }

.comp {
  position: absolute;
  top: 0px;
  width: 80px;
  height: 55px;
  border: 3px solid #fff;
  border-radius: 5px; }

.comp:after {
  content: '';
  position: absolute;
  z-index: 5;
  top: 19px;
  left: 5px;
  width: 65px;
  height: 10px;
  border-radius: 360px;
  border: 3px solid #fff; }

.overview_loader {
  position: absolute;
  z-index: 5;
  top: 23px;
  left: 11px;
  width: 8px;
  height: 8px;
  border-radius: 360px;
  background: #fff;
  -webkit-animation: loader 5s infinite linear 0.5s;
  -moz-animation: loader 5s infinite linear 0.5s;
  -o-animation: loader 5s infinite linear 0.5s;
  animation: loader 5s infinite linear 0.5s; }

.con {
  position: absolute;
  top: 28px;
  left: 85px;
  width: 100px;
  height: 3px;
  background: #fff; }

.byte {
  position: absolute;
  top: 25px;
  left: 80px;
  height: 9px;
  width: 9px;
  background: #fff;
  border-radius: 360px;
  z-index: 6;
  opacity: 0;
  -webkit-animation: byte_animate 5s infinite linear 0.5s;
  -moz-animation: byte_animate 5s infinite linear 0.5s;
  -o-animation: byte_animate 5s infinite linear 0.5s;
  animation: byte_animate 5s infinite linear 0.5s; }

.server {
  position: absolute;
  top: 22px;
  left: 185px;
  width: 35px;
  height: 35px;
  z-index: 1;
  border: 3px solid #fff;
  background: #ffffff66;
  border-radius: 360px;
  -webkit-transform: rotateX(58deg);
  -moz-transform: rotateX(58deg);
  -o-transform: rotateX(58deg);
  transform: rotateX(58deg); }

.server:before {
  content: '';
  position: absolute;
  top: -47px;
  left: -3px;
  width: 35px;
  height: 35px;
  z-index: 20;
  border: 3px solid #fff;
  background: #ffffff66;
  border-radius: 360px; }

.server:after {
  position: absolute;
  top: -26px;
  left: -3px;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  width: 35px;
  height: 40px;
  z-index: 17;
  background: #ffffff66;
  content: ''; }

/*Byte Animation*/
@-webkit-keyframes byte_animate {
  0% {
    opacity: 0;
    left: 80px; }
  4% {
    opacity: 1; }
  46% {
    opacity: 1; }
  50% {
    opacity: 0;
    left: 185px; }
  54% {
    opacity: 1; }
  96% {
    opacity: 1; }
  100% {
    opacity: 0;
    left: 80px; } }

@-moz-keyframes byte_animate {
  0% {
    opacity: 0;
    left: 80px; }
  4% {
    opacity: 1; }
  46% {
    opacity: 1; }
  50% {
    opacity: 0;
    left: 185px; }
  54% {
    opacity: 1; }
  96% {
    opacity: 1; }
  100% {
    opacity: 0;
    left: 80px; } }

@-o-keyframes byte_animate {
  0% {
    opacity: 0;
    left: 80px; }
  4% {
    opacity: 1; }
  46% {
    opacity: 1; }
  50% {
    opacity: 0;
    left: 185px; }
  54% {
    opacity: 1; }
  96% {
    opacity: 1; }
  100% {
    opacity: 0;
    left: 80px; } }

@keyframes byte_animate {
  0% {
    opacity: 0;
    left: 80px; }
  4% {
    opacity: 1; }
  46% {
    opacity: 1; }
  50% {
    opacity: 0;
    left: 185px; }
  54% {
    opacity: 1; }
  96% {
    opacity: 1; }
  100% {
    opacity: 0;
    left: 80px; } }

/*LOADER*/
@-webkit-keyframes loader {
  0% {
    width: 8px; }
  100% {
    width: 63px; } }

@-moz-keyframes loader {
  0% {
    width: 8px; }
  100% {
    width: 63px; } }

@-o-keyframes loader {
  0% {
    width: 8px; }
  100% {
    width: 63px; } }

@keyframes loader {
  0% {
    width: 8px; }
  100% {
    width: 63px; } }

/*FADE IN-OUT*/
@-webkit-keyframes fade-in-out {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    oapcity: 1; } }

@-moz-keyframes fade-in-out {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    oapcity: 1; } }

@-o-keyframes fade-in-out {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    oapcity: 1; } }

@keyframes fade-in-out {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    oapcity: 1; } }

/* ---------- Container for the orbiting circles animation ---------- */
#circle-orbit-container {
  position: absolute;
  top: 0;
  right: -100% !important;
  transition: all 0.2s;
  right: -170px;
  height: 100%;
  width: 300px; }

.neutral_theme.brand-selected #circle-orbit-container {
  right: -130px !important; }

/* ---------- Outer orbit - This is the circles furthest away from the central point ---------- */
#outer-orbit {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 550px;
  height: 650px;
  border: 2px #fff solid;
  border-radius: 100%; }

/* ---------- Repeating styles for the outer orbiting circles ---------- */
.outer-orbit-cirlces {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  background-color: #fff;
  transition: all .2s;
  border: 2px solid white;
  box-shadow: 0px 5px 28px 5px rgba(0, 0, 0, 0.2); }

.outer-orbit-cirlces:hover {
  transform: scale(2); }

.outer-orbit-cirlces.one {
  top: 55px;
  left: 18px; }

.outer-orbit-cirlces.two {
  top: 100px;
  left: -46px; }

.outer-orbit-cirlces.three {
  top: 150px;
  left: -45px; }

.outer-orbit-cirlces.four {
  top: 200px;
  left: 10px; }

#satellite {
  position: absolute;
  -webkit-animation: diagonal 180s infinite;
  -moz-animation: diagonal 180s infinite;
  -o-animation: diagonal 180s infinite;
  animation: diagonal 180s infinite; }

@-webkit-keyframes diagonal {
  0% {
    top: 0;
    left: 0; }
  50% {
    top: 10%;
    left: 50%; }
  100% {
    top: 20%;
    left: 100%; } }

.btn-cust {
  font-size: 0.75rem;
  background-color: #1c4961; }

/* ---Modal CSS -----*/
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1031;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow-x: hidden;
  background-color: #00000045;
  /* Black w/ opacity */
  -webkit-animation-name: wizardFadeIn;
  /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: wizardFadeIn;
  animation-duration: 0.4s;
  /* padding-top: $navbarHeight+0rem; */
  padding-top: 7.3rem; }

/* Modal Content */
.widget-container {
  padding: 2px; }

.modal > .modal-content > .widget-options > a > i, .Modal_Wrapper_Header > .modal-options > a > i {
  font-size: 17px;
  font-weight: bolder; }

.modal > .modal-content > .widget-options:hover > a, .Modal_Wrapper_Header > .modal-options:hover > a {
  color: white !important;
  background: #dc3545; }

.modal > .modal-content > .widget-options {
  top: 25px; }

.Modal_Wrapper_Header {
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.Modal_Wrapper_Body {
  display: flex;
  flex-grow: 1;
  height: calc(100% - 55px);
  padding-top: 5px;
  min-height: 0; }

.Modal_Wrapper_Header > .modal-options {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer; }

.modal-content {
  position: fixed;
  right: 0;
  background-color: #fefefe;
  width: 50%;
  height: calc(100% - 4.74rem);
  -webkit-animation-name: wizardSlideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: wizardSlideIn;
  animation-duration: 0.8s; }

.trips-page .modal-content .modal-content-center {
  width: 100%; }

@media only screen and (max-width: 1029px) {
  .modal-content .modal-content-center {
    width: 100%; } }

.modal-content-center {
  animation-name: wizardSlideIn;
  animation-duration: 0.8s;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 60rem;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  right: 15rem;
  height: 550px; }

.next {
  float: right;
  color: #0e51c3;
  font-size: 16px;
  letter-spacing: 5px;
  display: inline-flex; }

.previous {
  color: #0e51c3;
  font-size: 16px;
  letter-spacing: 5px;
  display: inline-flex; }

input[type="radio"] + label {
  font-weight: 400;
  text-transform: none; }

.modal-border {
  border: 0.1px outset #b7daff;
  padding: 30px;
  box-shadow: 1.5px 1.5px #9ebbd2; }

.pt1 {
  padding-top: 1rem !important; }

.white-space {
  white-space: normal !important; }

.row-link:hover {
  text-decoration: unset;
  font-weight: bold; }

.mainDiv {
  background-image: url(bg-1920x1080.png), -webkit-linear-gradient(top, #f2f7fe 0, #bcccdc 22%, #aac1cd 33%, #b7c9d9 55%, #bccddd 66%, #dfebfa 100%) !important;
  background-position: bottom;
  background-repeat: no-repeat;
  font: 300 16px/1.55 "Open Sans",sans-serif;
  color: #1f3f6c;
  background-color: #f2f7fe;
  cursor: default;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  user-select: none; }

.headerDiv {
  background: url(bosch_logo.png) no-repeat right top;
  background-position: right 0 top 8px;
  content: "";
  height: 70px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2; }

.loginDiv {
  width: 320px;
  height: 100%;
  position: fixed;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

.loginButton {
  border-color: #fff;
  height: 38px;
  font-size: 14px;
  border-radius: 4px;
  color: #e8eef3;
  border: 1px solid #1e4260;
  background-color: #26547a;
  background-image: linear-gradient(to bottom, #295a83 2%, #20496b 98%);
  box-shadow: inset 0 1px 0 #426d96, inset 0 -1px 0 #204666, 0 2px 3px rgba(0, 0, 0, 0.05);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.05);
  font-weight: bold;
  position: relative;
  text-align: center;
  white-space: nowrap;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  box-sizing: border-box;
  display: block;
  width: 100%; }

.softwareModuleDiv {
  width: 320px;
  height: 100%;
  position: absolute;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  top: 150px;
  left: 0;
  bottom: 0;
  right: 0;
  font: 300 14px/1.55 "Open Sans",sans-serif;
  color: black;
  font-weight: bold;
  font-size: small; }

.invalid {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545; }

.shadow-lite {
  box-shadow: �0 0.05rem 0.05rem rgba(0, 0, 0, 0.15) !important; }

.navbar {
  background-color: #fff !important; }

.navbar-brand img {
  height: auto !important; }

@media (max-width: 551px) {
  .highcharts-container {
    height: 5rem !important; } }

@media (min-width: 550px) {
  .highcharts-container {
    height: 7rem !important; }
  .headerDiv {
    background: none;
    background-position: right 0 top 0; } }

.toast__dismiss {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: block;
  flex: 0 0 auto;
  font: inherit;
  padding: 0;
  top: 5px;
  position: absolute;
  right: 15px;
  text-align: right; }

.toast__close {
  border-color: #fff;
  height: 38px;
  font-size: 14px;
  border-radius: 4px;
  color: #e8eef3;
  border: 1px solid #1e4260;
  background-color: #26547a;
  background-image: linear-gradient(to bottom, #295a83 2%, #20496b 98%);
  box-shadow: inset 0 1px 0 #426d96, inset 0 -1px 0 #204666, 0 2px 3px rgba(0, 0, 0, 0.05);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.05);
  font-weight: bold;
  position: relative;
  text-align: center;
  white-space: nowrap;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  box-sizing: border-box;
  display: block;
  width: 25%; }

.additional-metadata {
  margin-top: 0.5rem; }

#listDropdown {
  background: �white; }

/* for the loader */
.api-loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite; }
