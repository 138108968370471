/*.................General Navbar Config.....................*/
.navbar {
  transition: width 400ms;
  height: 6rem;
  position: fixed;
  background-color: #283e4a;
  top: 0;
  width: 100%;
  z-index: 1500; }

.navbar .navbar-nav:not(.action-items) .nav-link {
  color: #f6f6f7;
  width: 100%;
  height: 100%; }

:not(.sidenav-layout) > .navbar + .app-content {
  padding-top: 6.5rem;
  width: 100%; }

.sidenav-layout > .navbar + .app-content {
  background-color: #fff; }

.sidenav-layout > .navbar + .app-content > div:not(.init-loader) {
  background-color: #f2f2f2;
  height: 100%;
  padding-top: 2px; }

.navbar .navbar-nav > li > a > i {
  color: #d7d7d8; }

.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active {
  color: #f6f6f7;
  background-color: #35a6c9a8; }

.navbar .navbar-nav > li > a.active > i {
  color: #f6f6f7; }

.icon-border {
  margin-left: 5px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  align-items: center;
  display: flex;
  border: 1px solid #d7d7d8;
  justify-content: center; }

:not(.sidenav-layout) > .navbar .navbar-nav:not(.action-items) > .nav-item {
  min-width: 83px;
  width: 125px; }

:not(.sidenav-layout) > .navbar .navbar-nav.ml-auto > .nav-item {
  width: auto; }

:not(.sidenav-layout) > .navbar > .navbar-collapse,
:not(.sidenav-layout) > .navbar .navbar-nav {
  height: 100%; }

:not(.sidenav-layout) > .navbar {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

:not(.sidenav-layout) > .navbar .navbar-nav > .nav-item {
  align-items: center;
  justify-content: center;
  display: flex;
  -webkit-transition: background-color .3s;
  transition: background-color .3s; }

.navbar .navbar-nav > .nav-item > .nav-link:not(.user-avatar) {
  display: flex;
  align-items: center;
  justify-content: center; }

.navbar:not(.icon-stack) .navbar-nav > .nav-item > .nav-link:not(.user-avatar) {
  flex-direction: row; }

.navbar .navbar-nav > .nav-item > .nav-link:not(.user-avatar) i {
  width: 30px; }

.navbar .navbar-nav > .nav-item > .nav-link {
  text-align: center;
  font-size: 0.8rem;
  -webkit-transition: color .3s;
  transition: color .3s; }

.navbar-nav.action-items .nav-link span.badge {
  position: absolute;
  top: -8px;
  left: 65%; }

.navbar .navbar-nav:not(.action-items) > li:hover {
  background: #1e2e38; }

.navbar .navbar-nav:not(.action-items) > li:hover i {
  color: #005691 !important; }

.navbar .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: #f6f6f7 !important; }

.navbar .navbar-nav > .nav-item, .navbar .navbar-nav > .nav-item > .nav-link {
  position: relative; }

:not(.sidenav-layout) > .navbar .navbar-nav > li:hover > .treeview-menu-block {
  width: 180px;
  display: block !important;
  position: absolute;
  top: 100%;
  right: 5%;
  z-index: 1; }

:not(.sidenav-layout) > .navbar .navbar-nav > .nav-item > .treeview-menu-block {
  padding-top: 7px; }

.navbar .navbar-nav > .nav-item > .treeview-menu-block {
  display: none !important;
  -webkit-transform: translateZ(0);
  transform: translateZ(0); }

.navbar li > .treeview-menu-block > .treeview-menu {
  margin: 0 1px;
  background: #283e4a;
  -webkit-box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.4);
  box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.4); }

.treeview-menu {
  list-style: none;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0;
  padding-left: 5px; }

.treeview-menu > li {
  margin: 0; }

.treeview-menu > li > a {
  padding: 5px 5px 5px 15px;
  display: flex;
  align-items: center;
  font-size: 14px; }

.treeview-menu > li > a > .material-icons {
  margin-right: 10px; }

/*......................................*/
/*................Navbar Search Compoenent......................*/
.navbar-search input {
  border: 1px solid #f6f6f7;
  font-size: 12px;
  height: 30px;
  padding: 4px 8px;
  position: absolute;
  width: 50%;
  top: -2px; }

.navbar-search .autocompletecss {
  top: -22px;
  position: absolute;
  z-index: 0;
  right: -15px; }

.navbar-search input:focus {
  outline: none; }

.navbar-search button {
  text-align: center; }

.navbar-search button:focus {
  outline: none; }

.navbar-search button.btn-search, .navbar-search button.btn-reset {
  color: #d7d7d8;
  border: none;
  height: 30px;
  font-size: 12px;
  padding: 4px;
  position: absolute;
  width: 30px; }

.navbar-search > button.btn-search > i.material-icons {
  font-size: 20px !important; }

.navbar-search {
  width: 30px;
  height: 30px;
  position: relative; }

.navbar-search input {
  border-radius: 15px;
  transition: all .6s ease-in-out .3s;
  width: 200px;
  right: 0; }

/* .navbar-search input:focus,.navbar-search input:hover{
transition-delay: 0;

} */
.navbar-search input:focus ~ button.btn-search, .navbar-search input:focus ~ button.btn-search {
  color: #f6f6f7;
  left: 172px;
  transition-delay: 0; }

.navbar-search button, .navbar-search button {
  transition: all .6s ease-in-out; }

.navbar-search button.btn-search, .navbar-search button.btn-search {
  background: #283e4a;
  border-radius: 50%;
  height: 30px;
  right: 0px;
  transition-delay: .3s;
  width: 30px;
  border: 1px solid #f6f6f7;
  font-size: 20px;
  line-height: 0px;
  padding: 0;
  z-index: 2; }

button.btn.btn-search:hover + input {
  width: 200px; }

/*......................................*/
@media only screen and (min-width: 992px) {
  /*............Navbar No Icon Styling...........*/
  .navbar[class*="no-icon-nav"] .navbar-nav.navbar-navigation-menu > .nav-item > .nav-link > i {
    display: none; }
  /*............Navbar No Icon Styling End...........*/
  /*............Navbar NavItem width Config...........*/
  :not(.sidenav-layout) > .navbar[class*="icon-stack"] .navbar-nav > .nav-item {
    width: 83px; }
  /*............NNavbar NavItem width Config End...........*/
  /*............Navbar Height/Spacing Config...........*/
  :not(.sidenav-layout) > .navbar[class*="icon-stack"] {
    height: 6rem !important; }
  .navbar[class*="icon-stack"] .navbar-nav > .nav-item > .nav-link:not(.user-avatar) {
    flex-direction: column; }
  /*............Navbar Height/Spacing Config End...........*/
  .sidenav-layout > .navbar {
    height: 100%;
    width: 8rem;
    align-items: flex-start;
    flex-direction: column;
    padding: 0; }
  .sidenav-layout .admin-page-layout {
    padding-left: 0rem; }
  .sidenav-layout .subnav-menu {
    padding-left: 8rem;
    padding-right: 35%;
    width: 100% !important;
    height: 3rem !important; }
  .sidenav-layout .subnav-menu > .nav {
    flex-direction: row;
    flex-grow: 1;
    margin-top: unset; }
  .sidenav-layout .subnav-menu > .nav > li > a > span.title {
    top: 120%;
    left: -50%;
    background: #283e4a;
    color: #f6f6f7; }
  .sidenav-layout .subnav .nav a:hover, .sidenav-layout .subnav .nav .active {
    background: #283e4a;
    border-radius: 10px;
    box-shadow: unset; }
  .sidenav-layout .subnav-menu > .nav > li > a {
    width: 60% !important;
    position: relative;
    padding: 0.4rem !important;
    display: flex !important;
    align-items: center;
    justify-content: center; }
  .sidenav-layout > .navbar + .app-content {
    padding-top: 3rem;
    width: 100%;
    padding-left: 8rem; }
  .sidenav-layout > .navbar > .navbar-brand {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px; }
  .sidenav-layout > .navbar .navbar-nav {
    flex-direction: column;
    width: 100%; }
  .sidenav-layout > .navbar .navbar-collapse {
    align-items: flex-start;
    width: 100%;
    padding-top: 35px; }
  .sidenav-layout > .navbar[class*="icon-stack"]:not(.side-collapsed) .navbar-nav:not(.action-items) > .nav-item > .nav-link span {
    margin-top: .5rem; }
  .navbar[class*="icon-stack"] .navbar-nav:not(.action-items) .nav-link span.badge {
    position: absolute;
    top: 1px;
    left: 70%; }
  /*.....................................Side Collapsed .................*/
  .navbar.side-collapsed {
    width: 3rem; }
  .sidenav-layout > .navbar.icon-stack.side-collapsed .navbar-nav > li:hover > a > span {
    top: 0px;
    margin-left: 0;
    width: 180px;
    display: flex !important;
    position: absolute;
    left: 100%;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px; }
  .sidenav-layout > .navbar.icon-stack.side-collapsed .navbar-nav > li > a > span {
    margin: 0;
    height: 100%;
    font-size: 15px;
    background: #283e4a;
    -webkit-box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.4);
    box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.4); }
  /*................................................*/
  /*..............navbar submenu................*/
  .sidenav-layout > .navbar.icon-stack.side-collapsed .navbar-nav > li > a > span {
    display: none !important;
    -webkit-transform: translateZ(0);
    transform: translateZ(0); }
  .sidenav-layout > .navbar.icon-stack:not(.side-collapsed) .navbar-nav:not(.action-items) > li:hover > .treeview-menu-block {
    top: 20px;
    margin-left: 0; }
  .sidenav-layout > .navbar.icon-stack .navbar-nav:not(.action-items) > li:hover > .treeview-menu-block {
    width: 180px;
    display: block !important;
    position: absolute;
    left: 100%; }
  /*.....................................*/
  /*............Flexible Navbar.............*/
  .sidenav-layout > .navbar[class*="flexible-nav"] {
    position: fixed; }
  .sidenav-layout > .navbar[class*="flexible-nav"] .navbar-nav.action-items {
    position: fixed;
    right: 0;
    top: 0;
    width: auto;
    max-width: 300px;
    height: 3rem;
    flex-direction: row;
    align-items: center;
    background: #283e4a;
    z-index: 1;
    padding: 10px;
    border-bottom-left-radius: 8px; }
  .sidenav-layout > .navbar[class*="flexible-nav"] .navbar-nav.action-items > li:hover > .treeview-menu-block {
    width: 180px;
    display: block !important;
    position: absolute;
    top: 100%;
    right: -10px;
    z-index: 1; }
  .sidenav-layout > .navbar[class*="flexible-nav"] .navbar-nav.action-items > .nav-item > .treeview-menu-block {
    padding-top: 7px; }
  /*..............................*/ }

/*................Mobile............*/
@media only screen and (max-width: 992px) {
  .navbar .navbar-nav.navbar-navigation-menu {
    position: absolute;
    left: 0;
    top: 5rem;
    flex-direction: row;
    height: 100vh !important;
    background: #283e4af2;
    width: 100% !important;
    flex-wrap: wrap;
    justify-content: flex-start;
    background: white;
    align-items: flex-start;
    overflow: auto;
    height: auto; }
  .navbar[class*="icon-stack"] .navbar-nav > .nav-item > .nav-link:not(.user-avatar) {
    flex-direction: column; }
  .navbar .navbar-nav.action-items {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3rem;
    flex-direction: row;
    justify-content: flex-end;
    background: #fff; }
  .collapse.navbar-collapse {
    display: none; }
  .navbar-toggler {
    display: block; }
  .user-avatar {
    position: absolute !important;
    bottom: 100%;
    right: -20px; }
  :not(.sidenav-layout) > .navbar .navbar-nav:not(.action-items) > .nav-item {
    width: 50%; }
  :not(.sidenav-layout) > .navbar .navbar-nav > .nav-item {
    justify-content: flex-start; }
  :not(.sidenav-layout) > .navbar .navbar-nav > .nav-item > .treeview-menu-block {
    width: 100% !important;
    padding: 0; } }

/*......................................*/
/*...........Subnav..........*/
.sidenav-layout .sidebar.sidebarReports.inactive, .sidenav-layout .sidebar.sidebarFleet:not(.inactive) {
  left: calc(-22% + 8rem); }

.sidenav-layout .sidebar.sidebarReports, .sidenav-layout .sidebar.sidebarFleet.inactive {
  top: 3rem;
  left: 8rem; }

.sidenav-layout .sidebar.sidebarFleet {
  top: 3rem; }

.sidenav-layout .modal {
  padding-top: 3rem; }

.sidenav-layout .modal-content {
  height: 100%; }

/*..............................*/
/*...........Nav Logo..........*/
/*..............................*/
